import {
  ActionType,
  CreateUserData,
  WebviewType,
  MessageType,
  UserEventData,
  WidgetInfoData,
} from "@app/neva/models";
import { useContext } from "react";
import { ConfigContext, ServiceContext } from "@app/provider";
import { NevaContext } from "@app/neva/provider";

export type UseApiControllerType = {
  getWidgetInfo: () => Promise<WidgetInfoData>;
  createUser: (data: CreateUserInterface) => Promise<CreateUserData>;
  handleUserEvent: (
    data: HandleUserEventPayloadInterface
  ) => Promise<UserEventData>;
  getWebview: (webviewId: string, chatId: number) => Promise<WebviewType>;
};

export interface CreateUserInterface {
  nevaPreSessionToken: string;
  language: string;
  timezone: string;
  heapInfo: HeapInfo;
  userDetails: UserDetails;
}

export interface HeapInfo {
  heapIoIdentity: string;
  heapIoPrefix: string;
}

export interface UserDetails {
  patientUid?: string;
  patientOktaUid?: string;
  documentUid?: number;
  orderUid?: string;
}

export interface HandleUserEventInterface {
  action?: ActionType;
  message?: MessageType;
}

export interface HandleUserEventPayloadInterface
  extends HandleUserEventInterface {
  chatId: number;
}

enum ApiRequests {
  GET = "GET",
  POST = "POST",
}

type HeadersType = {
  "Content-Type": string;
  Authorization?: string;
};

export const useApiController = (): UseApiControllerType => {
  const {
    config: {
      nevaConfig: { apiVersion },
    },
  } = useContext(ConfigContext);
  const { sessionService } = useContext(ServiceContext);
  const { botId } = useContext(NevaContext);

  const getHeaders = async () => {
    const headers: HeadersType = {
      "Content-Type": "application/json;charset=UTF-8",
    };
    const token = await sessionService.getToken();
    if (token?.accessToken) {
      headers.Authorization = `Bearer ${token.accessToken}`;
    }
    return headers;
  };

  const getWidgetInfo = async (): Promise<WidgetInfoData> => {
    const headers = await getHeaders();
    const response = await fetch(
      `/neva/api/${apiVersion}/widget/info/${botId}`,
      {
        method: ApiRequests.GET,
        headers,
      }
    );

    if (!response.ok) {
      await responseErrorHandler(response);
    }

    return response.json();
  };

  const createUser = async (
    data: CreateUserInterface
  ): Promise<CreateUserData> => {
    const headers = await getHeaders();
    const response = await fetch(
      `/neva/api/${apiVersion}/natera/user/${botId}`,
      {
        method: ApiRequests.POST,
        headers,
        body: JSON.stringify(data),
      }
    );

    if (!response.ok) {
      await responseErrorHandler(response);
    }

    return response.json();
  };

  const handleUserEvent = async (
    data: HandleUserEventPayloadInterface
  ): Promise<UserEventData> => {
    const headers = await getHeaders();
    const response = await fetch(
      `/neva/api/${apiVersion}/widget/handleEvent/${botId}`,
      {
        method: ApiRequests.POST,
        headers,
        body: JSON.stringify(data),
      }
    );

    if (!response.ok) {
      await responseErrorHandler(response);
    }

    return response.json();
  };

  const getWebview = async (
    webviewId: string,
    chatId: number
  ): Promise<WebviewType> => {
    const headers = await getHeaders();
    const response = await fetch(
      `/neva/api/${apiVersion}/webview/${webviewId}/${chatId}`,
      {
        method: ApiRequests.GET,
        headers,
      }
    );

    if (!response.ok) {
      await responseErrorHandler(response);
    }

    return response.json();
  };

  const responseErrorHandler = async (response: Response): Promise<Error> => {
    const data = await response.json();
    throw new Error(data.error.message);
  };

  return {
    getWidgetInfo,
    createUser,
    handleUserEvent,
    getWebview,
  };
};
