import { DocumentNode, gql } from "@apollo/client";

export interface StartRenewalWorkflowResponse {
  success: boolean;
}
export class RenewalInfoResponse {
  isLastSignateraOrder: boolean;
  hasRenewal: boolean;
  renewalOrderUid?: string;
  clinicLimsId?: number;
}

export default class OrderRenewalService {
  public static startRenewalWorkflow = (): DocumentNode => gql`
    query StartRenewalWorkflow {
      startRenewalWorkflow {
        success
      }
    }
  `;

  public static getRenewalInfo = (): DocumentNode => gql`
    query GetRenewalInfo($orderUid: String, $token: String) {
      getRenewalInfo(orderUid: $orderUid, token: $token) {
        isLastSignateraOrder
        hasRenewal
        renewalOrderUid
        clinicLimsId
      }
    }
  `;
}
