import React from "react";
import FinancialAidIcon from "@assets/svg/financial-aid.svg";
import { Svg } from "@natera/material/lib/svg";
import DollarIcon from "@assets/svg/icons/dollar.svg";
import { Button } from "@natera/platform/lib/components/form";
import { defineMessages, useIntl } from "react-intl";
import { useDialog } from "@natera/platform/lib/hooks";
import { CompassionateCareDialog } from "@app/components";

import "./financialAidCard.scss";
import { HEAP_EVENTS } from "@app/provider/types";
import { getCardTypeForHeap } from "../secondaryActionCard/secondaryActionCardParams";
import { SecondaryActionCardType } from "@app/provider/testData/types";

const messages = defineMessages({
  financialAidCardLearnMore: {
    id: "financialAidCardLearnMore",
    defaultMessage: "Learn More",
  },
});

type Props = {
  title: string;
  description: string;
  note?: string;
  showIcon?: boolean;
  showDialog?: boolean;
  limsClinicId?: number;
};

const FinancialAidCard: React.FC<Props> = ({
  title,
  description,
  note,
  showIcon,
  showDialog,
  limsClinicId,
}) => {
  const intl = useIntl();
  const compassionateCareDialog = useDialog(CompassionateCareDialog);

  const compassionateCareAction = () => {
    compassionateCareDialog.open({
      onCloseDialog: () => compassionateCareDialog.close(),
    });
    heap.track(HEAP_EVENTS.upp_secondarycard_click, {
      card_type: getCardTypeForHeap(SecondaryActionCardType.COMPASSIONATE_CARE),
      lims_clinic_id: limsClinicId,
    });
  };

  return (
    <>
      {showDialog && compassionateCareDialog.getDialog()}
      <div className="financial-aid-card">
        {showIcon && (
          <Svg className="financial-aid-card__icon" content={DollarIcon} />
        )}
        <div className="financial-aid-card__image">
          <Svg
            className="financial-aid-card__image__svg"
            content={FinancialAidIcon}
          />
        </div>
        <div className="financial-aid-card__content">
          <span className="financial-aid-card__content__title">{title}</span>
          <p className="financial-aid-card__content__description">
            {description}
          </p>
          {note && (
            <p className="financial-aid-card__content__description">{note}</p>
          )}
          {showDialog && (
            <div className="financial-aid-card__content__btn">
              <Button outlined onClick={compassionateCareAction}>
                {intl.formatMessage(messages.financialAidCardLearnMore)}
              </Button>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default FinancialAidCard;
