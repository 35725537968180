import { Clinic } from "@app/provider/types";
import { SimpleOrderWorkflowState } from "@app/provider/order";
import { GenderOption } from "../testResult";

export enum ManagedBy {
  CLINIC = "CLINIC",
  NATERA = "NATERA",
}

export enum KitAppStatus {
  SHIPPED = "SHIPPED",
}

export enum ProsperaOrganType {
  HEART = "HEART",
  KIDNEY = "KIDNEY",
  LIVER = "LIVER",
  LUNG = "LUNG",
  SPK = "SPK",
  OTHER = "OTHER",
}

export enum SampleType {
  BLOOD = "BLOOD",
  SALIVA = "SALIVA",
  TISSUE = "TISSUE",
}

export enum TestCardStatus {
  TEST_ORDERED = "TEST_ORDERED",
  UPCOMING_TEST = "UPCOMING_TEST",
  KIT_SHIPPED = "KIT_SHIPPED",
  APPT_REQUESTED = "APPT_REQUESTED",
  APPT_CONFIRMED = "APPT_CONFIRMED",
  SAMPLE_RECEIVED = "SAMPLE_RECEIVED",
  SAMPLES_RECEIVED = "SAMPLES_RECEIVED",
  RESULT_READY = "RESULT_READY",
  TEST_COMPLETE = "TEST_COMPLETE",
  CANCELLED_TEST = "CANCELLED_TEST",
  EXPIRED_TEST = "EXPIRED_TEST",
  REDRAW_NEEDED = "REDRAW_NEEDED",
  NEW_TISSUE_NEEDED = "NEW_TISSUE_NEEDED",
}

export enum TestStatus {
  NOT_ACCESSED = "NOT_ACCESSED",
  VIEWED = "VIEWED",
  VIEW_WITH_PHYSICIAN = "VIEW_WITH_PHYSICIAN",
}

export enum TestType {
  ALTERA = "ALTERA",
  ANORA = "ANORA",
  EMPOWER = "EMPOWER",
  HORIZON = "HORIZON",
  PANORAMA = "PANORAMA",
  PROSPERA = "PROSPERA",
  RENASIGHT = "RENASIGHT",
  SIGNATERA = "SIGNATERA",
  SPECTRUM = "SPECTRUM",
  VISTARA = "VISTARA",
}

export const ONCOLOGY_TEST_TYPES = [
  TestType.ALTERA,
  TestType.EMPOWER,
  TestType.SIGNATERA,
];

export enum ComboTestType {
  HORIZON_PANORAMA_COMBO = "HORIZON_PANORAMA_COMBO",
  PANORAMA_VISTARA_COMBO = "PANORAMA_VISTARA_COMBO",
  HORIZON_VISTARA_COMBO = "HORIZON_VISTARA_COMBO",
  COMBO_VISTARA = "COMBO_VISTARA",
}

export enum MobilePhlebotomyJobType {
  PROSPERA = "PROSPERA",
  PROSPERA_HEART = "PROSPERA_HEART",
  PROSPERA_LUNG = "PROSPERA_LUNG",
  RENASIGHT = "RENASIGHT",
  HORIZON = "HORIZON",
  PANORAMA = "PANORAMA",
  EMPOWER = "EMPOWER",
  SIGNATERA = "SIGNATERA",
  ALTERA = "ALTERA",
  VISTARA = "VISTARA",
  SPECTRUM = "SPECTRUM",
  ANORA = "ANORA",
  HORIZON_PANORAMA_COMBO = "HORIZON_PANORAMA_COMBO",
  PANORAMA_VISTARA_COMBO = "PANORAMA_VISTARA_COMBO",
  HORIZON_VISTARA_COMBO = "HORIZON_VISTARA_COMBO",
  COMBO_VISTARA = "COMBO_VISTARA",
}

export enum PrimaryAction {
  SCHEDULE_A_DRAW = "SCHEDULE_A_DRAW",
  TRACK_KIT_STATUS = "TRACK_KIT_STATUS",
  APPOINTMENT_DETAIL = "APPOINTMENT_DETAIL",
  VIEW_YOUR_RESULTS = "VIEW_YOUR_RESULTS",
  COMPLETE_ORDER = "COMPLETE_ORDER",
  VIEW_TEST_DETAILS = "VIEW_TEST_DETAILS",
  VIEW_RESULT_PAGE = "VIEW_RESULT_PAGE",
}

export enum SecondaryActionCardType {
  COMPASSIONATE_CARE = "COMPASSIONATE_CARE",
  SALIVA = "SALIVA",
  EMPOWER = "EMPOWER",
  HORIZON = "HORIZON",
  PANORAMA = "PANORAMA",
  PROSPERA_KIDNEY = "PROSPERA_KIDNEY",
  PROSPERA_SPK = "PROSPERA_SPK",
  PROSPERA_HEART = "PROSPERA_HEART",
  PROSPERA_LIVER = "PROSPERA_LIVER",
  PROSPERA_LUNG = "PROSPERA_LUNG",
  PROSPERA_OTHER = "PROSPERA_OTHER",
  RENASIGHT_KIDNEY = "RENASIGHT_KIDNEY",
  VISTARA = "VISTARA",
  GENETIC_TESTING = "GENETIC_TESTING",
  WOMENS_HEALTH_PRICING_BILLING = "WOMENS_HEALTH_PRICING_BILLING",
  ONCOLOGY_PRICING_BILLING = "ONCOLOGY_PRICING_BILLING",
  PANORAMA_HORIZON = "PANORAMA_HORIZON",
  ALTERA = "ALTERA",
  SIGNATERA = "SIGNATERA",
}

export enum SecondaryActionCardTypeForHeapEvent {
  COMPCARE_INFO = "compcare_info",
  BILLING_FAQ_WH = "billing_FAQ_WH",
  BILLING_FAQ_ONC = "billing_FAQ_ONC",
  EMPOWER_INFO = "empower_info",
  HORIZON_INFO = "horizon_info",
  VISTARA_INFO = "vistara_info",
  PANORAMA_INFO = "panorama_info",
  RENASIGHT_INFO = "renasight_info",
  PROSPERA_INFO = "prospera_info",
  SALIVA_COLLECTION_INFO = "saliva_collection_info",
  MULTITEST_INFO_WH = "multitest_info_WH",
  HORIZON_PANORAMA_INFO = "horizon_panorama_info",
  ALTERA_INFO = "altera_info",
  SIGNATERA_INFO = "signatera_info",
}

export interface TestCard {
  uid: string;
  orderUid: string;
  testType: TestType;
  status: TestCardStatus;
  nextStatus?: TestCardStatus;
  managedBy?: ManagedBy;
  sampleCollectionDate?: Date;
  sampleType: SampleType;
  sampleReceivedDate?: Date;
  PTPOption: boolean;
  viewStatus?: TestStatus;
  organType?: ProsperaOrganType;
  createdAt: Date;
  accessedResultAt?: Date;
  kitAppStatus?: KitAppStatus;
  trackingNumber?: string;
  kitShippedDate?: string;
  reportGender?: boolean;
  caPnsSupplemental?: boolean;
  resultCode?: string;
  fetalSex?: GenderOption;
  resultReleasedAt: Date | null;
  latestResultDocumentUid?: string;
  cancelledAt?: Date;
  isProReach?: boolean;
  clinic?: Clinic;
  isComboOrder?: boolean;
  isNevaEnabled?: boolean;
  isOrderInSignedOrdersTable?: boolean;
  simpleOrderWorkflowState?: SimpleOrderWorkflowState;
  documentReferenceId?: number;
  isSignateraSubsequent?: boolean;
  isOncologyOrder?: boolean;
  isEmpowerStatPanelIncluded?: boolean;
  isLatestReleasedResultStat?: boolean;
  isStatResultAlreadyViewed?: boolean;
}

export interface ParsedTestCard {
  uid: string;
  orderUid: string;
  testType: TestType;
  createdAt: Date;
  accessedResultAt?: Date;
  status: TestCardStatus;
  nextStatus?: TestCardStatus;
  description?: string;
  primaryAction?: PrimaryAction;
  organType?: ProsperaOrganType;
  managedBy?: ManagedBy;
  sampleType?: SampleType;
  sampleReceivedDate?: Date;
  trackingNumber?: string;
  reportGender?: boolean;
  caPnsSupplemental?: boolean;
  resultCode?: string;
  fetalSex?: GenderOption;
  resultReleasedAt: Date | null;
  latestResultDocumentUid?: string;
  cancelledAt?: Date;
  isProReach?: boolean;
  clinic?: Clinic;
  isComboOrder?: boolean;
  isNevaEnabled?: boolean;
  isOrderInSignedOrdersTable?: boolean;
  simpleOrderWorkflowState?: SimpleOrderWorkflowState;
  documentReferenceId?: number;
  isSignateraSubsequent?: boolean;
  isEmpowerStatPanelIncluded?: boolean;
  isLatestReleasedResultStat?: boolean;
  isStatResultAlreadyViewed?: boolean;
}

export interface SecondaryCard {
  type: SecondaryActionCardType;
  title?: string;
  description?: string;
  note?: string;
  limsClinicId?: number;
}
