import React, { FC } from "react";

import { RuntimeConfigurationProvider } from "@natera/platform/lib/provider/runtimeConfiguration";
import {
  ConfigProvider,
  ServiceProvider,
  UppAuthProvider,
  IntlProvider,
  NotificationProvider,
  IdleWrapper,
  TestResultProvider,
  UnifiedPatientPortalServerProvider,
  UserProvider,
} from "@app/provider";
import AuthProviderWrapper from "@app/provider/authProviderWrapper";
import SecurityProviderWrapper from "@app/provider/securityProviderWrapper";
import config from "@etc/config.json";
import oktaConfig from "@etc/okta.json";
import maintenanceConfig from "@etc/maintenance.json";
import Routing from "./routing";
import Maintenance from "@app/pages/public/maintenance";

const App: FC = () => {
  return (
    <IntlProvider>
      <RuntimeConfigurationProvider>
        <ConfigProvider
          config={{
            ...config,
            okta: oktaConfig,
            maintenance: maintenanceConfig,
          }}
        >
          <Maintenance>
            <ServiceProvider>
              <NotificationProvider>
                <AuthProviderWrapper>
                  <SecurityProviderWrapper>
                    <UnifiedPatientPortalServerProvider>
                      <UppAuthProvider>
                        <UserProvider>
                          <IdleWrapper>
                            <TestResultProvider>
                              <Routing />
                            </TestResultProvider>
                          </IdleWrapper>
                        </UserProvider>
                      </UppAuthProvider>
                    </UnifiedPatientPortalServerProvider>
                  </SecurityProviderWrapper>
                </AuthProviderWrapper>
              </NotificationProvider>
            </ServiceProvider>
          </Maintenance>
        </ConfigProvider>
      </RuntimeConfigurationProvider>
    </IntlProvider>
  );
};

export default App;
