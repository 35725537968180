import React, { FC, useContext } from "react";
import { defineMessages, useIntl } from "react-intl";
import { useHistory } from "react-router-dom";
import { TestResult } from "@app/provider/testResult";

import { TestType, TestCardStatus } from "@app/provider/testData/types";
import { routes } from "@app/routing";
import SubCard from "@app/components/subCard/subCard";
import Documents from "@assets/svg/documents.svg";
import "../details/testResultDetails.scss";
import useTestResultsTexts from "@app/hooks/useTestResultsTexts";
import { TestDetailsContext } from "@app/provider";

const messages = defineMessages({
  testCardDownloadableResultsGuide: {
    id: "testCardDownloadableResultsGuide",
    defaultMessage: "Downloadable {product} results guide",
  },
});

interface HistoryWithState {
  from?: string;
}

interface Props {
  testResult: TestResult;
}

const DownloadableResultsGuideSubCard: FC<Props> = ({ testResult }) => {
  const intl = useIntl();
  const history = useHistory<HistoryWithState | undefined>();

  const { getTestDetails } = useContext(TestDetailsContext);
  const testResultsTexts = useTestResultsTexts();
  const testDetails = getTestDetails();

  const testTitle =
    testResult?.testType &&
    testResultsTexts.getTestResultDetailsTitle(testResult?.testType);

  const onClickPreTestInfo = () => {
    if (isDownloadableResultsGuideNeeded(testResult)) {
      const downloadableResultsGuideRoute = `${routes.downloadableResultsGuidePage(
        testResult.orderUid,
        testResult.testUid
      )}`;

      history.push(downloadableResultsGuideRoute);
    }
  };

  const isDownloadableResultsGuideNeeded = (
    testResult: TestResult
  ): boolean => {
    if (!testDetails) {
      return false;
    }

    return (
      [TestType.ALTERA].includes(testResult.testType) &&
      [TestCardStatus.RESULT_READY, TestCardStatus.TEST_COMPLETE].includes(
        testDetails.status
      )
    );
  };

  return isDownloadableResultsGuideNeeded(testResult) ? (
    <SubCard
      onClick={onClickPreTestInfo}
      icon={Documents}
      title={intl.formatMessage(messages.testCardDownloadableResultsGuide, {
        product: testTitle,
      })}
    />
  ) : (
    <></>
  );
};

export default DownloadableResultsGuideSubCard;
