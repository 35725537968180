import React, { FC, ReactNode, useContext } from "react";
import { useHistory } from "react-router-dom";
import { ConfigContext, FetalSexContext } from "@app/provider";
import { CaPnsSupplementalDialog } from "@app/components";
import { FetalSexDialog } from "@app/components/viewResultDialogs";
import { useDialog } from "@natera/platform/lib/hooks";
import {
  ParsedTestCard,
  ProsperaOrganType,
  TestCardStatus,
  TestType,
} from "@app/provider/testData/types";
import { routes } from "@app/routing";
import { GenderOption } from "./testResult";

interface ViewResultController {
  openResultPage: (testCard: ParsedTestCard) => void;
  openTestResultDetailsPage: (
    testCard: ParsedTestCard,
    isHistory?: boolean
  ) => void;
  openShareFetalSexPage: (
    testCard: ParsedTestCard,
    isHistory?: boolean
  ) => void;
}

interface ViewResultsProvider {
  children: ReactNode;
}

export const Context = React.createContext<ViewResultController>({
  openResultPage: () => undefined,
  openTestResultDetailsPage: () => undefined,
  openShareFetalSexPage: () => undefined,
});

export const isNeedPreTestInfo = (testType: TestType): boolean =>
  [
    TestType.VISTARA,
    TestType.EMPOWER,
    TestType.PROSPERA,
    TestType.RENASIGHT,
    TestType.ALTERA,
    TestType.SIGNATERA,
  ].includes(testType);

Context.displayName = "ViewResultContext";

const ViewResultProvider: FC<ViewResultsProvider> = ({ children }) => {
  const fetalSexDialog = useDialog(FetalSexDialog);
  const caPnsSupplementalDialog = useDialog(CaPnsSupplementalDialog);
  const history = useHistory();
  const { getFetalSexIsShown } = useContext(FetalSexContext);
  const { config } = useContext(ConfigContext);

  const openTestResultDetailsPage = (
    testCard: ParsedTestCard,
    isHistory?: boolean
  ) => {
    let from;
    if (isHistory) {
      from = routes.testHistory;
    }
    openResultDetailsPage(testCard.orderUid, testCard.uid, from);
  };

  const openResultDetailsPage = (
    orderUid: string,
    testUid: string,
    from?: string
  ) =>
    history.push(routes.testResultDetailsPage(orderUid, testUid), {
      from,
    });

  const openPdfPage = (
    orderUid: string,
    testUid: string,
    testDocumentUid: string
  ) =>
    history.push(routes.testResultPdfPage(orderUid, testUid, testDocumentUid));

  const openPreTestInfoPage = (
    orderUid: string,
    testUid: string,
    status: TestCardStatus,
    organType: ProsperaOrganType | undefined,
    testDocumentUid: string
  ) =>
    history.push(
      `${routes.understandingYourResultsPage(
        orderUid,
        testUid,
        status,
        testDocumentUid
      )}${organType ? `?organType=${organType}` : ""}`
    );

  const openResultPage = (testCard: ParsedTestCard) => {
    const {
      testType,
      orderUid,
      uid,
      isNevaEnabled,
      latestResultDocumentUid,
    } = testCard;

    if (testType === TestType.PANORAMA) {
      handlePanoramaResult(testCard);
    } else if (
      isNeedPreTestInfo(testCard.testType) &&
      !(isNevaEnabled && config.nevaConfig.enabled)
    ) {
      handleTestWithPreResultInfo(testCard);
    } else {
      openPdfPage(orderUid, uid, latestResultDocumentUid ?? "");
    }
  };

  const openShareFetalSexPage = (testCard: ParsedTestCard) => {
    history.push(routes.shareFetalSexPage(testCard.orderUid, testCard.uid));
  };

  const handlePanoramaResult = async (testCard: ParsedTestCard) => {
    const fetalSexFlag = await getFetalSexIsShown(testCard.orderUid);
    const fetalSexIsShown = fetalSexFlag?.fetalSexIsShown;

    const invalidFetalSexValues = [
      GenderOption.UNKNOWN,
      GenderOption.MASKED,
      GenderOption.NOT_REPORTED,
    ];

    const isRequiredToAskAboutFetalSex =
      testCard.reportGender &&
      testCard.resultCode !== "TNP" &&
      (!testCard.fetalSex ||
        !invalidFetalSexValues.includes(testCard.fetalSex)) &&
      !fetalSexIsShown;

    if (testCard.caPnsSupplemental) {
      caPnsSupplementalDialog.open({
        onCloseDialog: () => caPnsSupplementalDialog.close(),
        orderUid: testCard.orderUid,
        testUid: testCard.uid,
        testDocumentUid: testCard.latestResultDocumentUid ?? "",
      });
    } else if (isRequiredToAskAboutFetalSex) {
      fetalSexDialog.open({
        onCloseDialog: () => fetalSexDialog.close(),
        orderUid: testCard.orderUid,
        testUid: testCard.uid,
        testDocumentUid: testCard.latestResultDocumentUid ?? "",
        limsClinicId: testCard.clinic?.limsId,
      });
    } else {
      openPdfPage(
        testCard.orderUid,
        testCard.uid,
        testCard.latestResultDocumentUid ?? ""
      );
    }
  };

  const handleTestWithPreResultInfo = async (testCard: ParsedTestCard) => {
    const isEmpowerStatAlreadyViewed =
      testCard.status === TestCardStatus.RESULT_READY &&
      testCard.testType === TestType.EMPOWER &&
      testCard.isLatestReleasedResultStat &&
      testCard.isStatResultAlreadyViewed;

    if (
      testCard.status == TestCardStatus.RESULT_READY &&
      !isEmpowerStatAlreadyViewed
    ) {
      openPreTestInfoPage(
        testCard.orderUid,
        testCard.uid,
        testCard.status,
        testCard.organType,
        testCard.latestResultDocumentUid ?? ""
      );
    } else {
      openPdfPage(
        testCard.orderUid,
        testCard.uid,
        testCard.latestResultDocumentUid ?? ""
      );
    }
  };

  const viewResultController: ViewResultController = React.useMemo(
    () => ({
      openTestResultDetailsPage,
      openResultPage,
      openShareFetalSexPage,
    }),
    [openTestResultDetailsPage, openResultPage, openShareFetalSexPage]
  );

  return (
    <Context.Provider value={viewResultController}>
      {fetalSexDialog.getDialog()}
      {caPnsSupplementalDialog.getDialog()}
      {children}
    </Context.Provider>
  );
};

export default ViewResultProvider;
