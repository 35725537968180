import { HeapPHIDecorator } from "@app/components/analytics";
import React, {
  FC,
  ReactElement,
  useContext,
  useState,
  useEffect,
} from "react";
import { useIntl, defineMessages } from "react-intl";

import {
  SetValidationError,
  ClearErrors,
  ClearValidationError,
} from "@natera/platform/lib/hooks/useError";
import { Form, Button } from "@natera/platform/lib/components/form";
import { Textfield } from "@natera/platform/lib/components/form/field";
import { Tooltip } from "@natera/material/lib/tooltip";
import {
  ConfigContext,
  ErrorContext,
  IntlContext,
  Language,
  PasswordPolicyContext,
  SignUpContext,
} from "@app/provider";
import {
  DropdownWrapper,
  FormField,
  Notification,
  PasswordField,
  PasswordNotice,
  usePasswordPolicy,
} from "@app/components";
import { validateEmail } from "@app/utils";
import MailIcon from "@assets/svg/icons/mail.svg";
import { saveLoginMethodToCookie } from "@app/utils/cookiesHelper";
import { IDP_TYPE } from "@app/service/user";
import { isPasswordValid } from "@app/utils/validatePassword";

const messages = defineMessages({
  signUpByPasswordFormEmailAddress: {
    id: "signUpByPasswordFormEmailAddress",
    defaultMessage: "Email Address",
  },
  signUpByPasswordFormFillFieldError: {
    id: "signUpByPasswordFormFillFieldError",
    defaultMessage: "Please fill in this field",
  },
  signUpByPasswordFormPasswordLabel: {
    id: "signUpByPasswordFormPasswordLabel",
    defaultMessage: "Password",
  },
  signUpByPasswordFormCreateAccount: {
    id: "signUpByPasswordFormCreateAccount",
    defaultMessage: "Create Account",
  },
  signUpByPasswordFormEmailAddressPlaceholder: {
    id: "signUpByPasswordFormEmailAddressPlaceholder",
    defaultMessage: "Create Account with Email Address",
  },
  signUpByPasswordFormPasswordPlaceholder: {
    id: "signUpByPasswordFormPasswordPlaceholder",
    defaultMessage: "Create Password",
  },
  signUpByPasswordFormEmailFieldNotice: {
    id: "signUpByPasswordFormEmailFieldNotice",
    defaultMessage:
      "Account activation link will be sent to this email address",
  },
  signUpByPasswordFormEmailInvalid: {
    id: "signUpByPasswordFormEmailInvalid",
    defaultMessage: "Please enter a valid email address. (username@domain)",
  },
  signUpByPasswordFormUserExisted: {
    id: "signUpByPasswordFormUserExisted",
    defaultMessage: "This email address is already in use",
  },
  signUpByPasswordFormPasswordInvalid: {
    id: "signUpByPasswordFormPasswordInvalid",
    defaultMessage: "Invalid password",
  },
  signUpByPasswordFormEmailFormat: {
    id: "signUpByPasswordFormEmailFormat",
    defaultMessage: "email format (username@domain)",
  },
  signUpByPasswordFormSignInTitle: {
    id: "signUpByPasswordFormSignInTitle",
    defaultMessage: "Log In",
  },
});

interface SignUpByPasswordFormProps {
  setValidationError: SetValidationError;
  clearValidationError: ClearValidationError;
  clearErrors: ClearErrors;
  onSubmit: (props: {
    email: string;
    password: string;
    language: Language;
  }) => void;
  initialEmail?: string;
}

const SignUpByPasswordForm: FC<SignUpByPasswordFormProps> = ({
  setValidationError,
  clearValidationError,
  clearErrors,
  onSubmit,
  initialEmail,
}) => {
  const intl = useIntl();

  const { config } = React.useContext(ConfigContext);
  const { currentLanguage } = useContext(IntlContext);
  const { getValidationError } = useContext(ErrorContext);
  const { getPasswordPolicy } = useContext(PasswordPolicyContext);
  const {
    checkUserExistedData,
    checkUserExisted,
    checkUserExistedIsLoading,
  } = useContext(SignUpContext);

  const usePlusSignInEmailAddress =
    config.test.usePlusSignInEmailAddress.enabled;

  const [emailNoticeIsVisible, setEmailNoticeIsVisible] = useState(false);
  const [passwordNoticeIsVisible, setPasswordNoticeIsVisible] = useState(false);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const {
    passwordPolicyComplexity: passwordPolicyComplexityValidationState,
  } = usePasswordPolicy({ email, password, currentLanguage });
  const [submitIsDisabled, setSubmitIsDisabled] = useState(false);
  const [emailTooltipIsOpened, setEmailTooltipIsOpened] = useState(false);

  useEffect(() => {
    if (initialEmail) {
      setEmail(initialEmail);
    }
  }, [initialEmail]);

  useEffect(() => {
    getPasswordPolicy();

    return () => {
      clearErrors();
    };
  }, []);

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();

    clearErrors();

    const formData = e.currentTarget;

    const email = formData.email.value;
    const password = formData.password.value;

    if (!email) {
      setValidationError(
        "email",
        intl.formatMessage(messages.signUpByPasswordFormFillFieldError)
      );

      return;
    }

    if (!password) {
      setValidationError(
        "password",
        intl.formatMessage(messages.signUpByPasswordFormFillFieldError)
      );

      return;
    }

    if (!validateEmail(email, usePlusSignInEmailAddress)) {
      setValidationError(
        "email",
        intl.formatMessage(messages.signUpByPasswordFormEmailInvalid)
      );

      return;
    }

    const invalidPasswordRequirement = !isPasswordValid({
      password,
      email,
      passwordPolicyComplexity: passwordPolicyComplexityValidationState,
    });

    if (invalidPasswordRequirement) {
      setValidationError(
        "password",
        intl.formatMessage(messages.signUpByPasswordFormPasswordInvalid)
      );

      return;
    }

    if (checkUserExistedData?.isExisted) {
      setValidationError(
        "email",
        intl.formatMessage(messages.signUpByPasswordFormUserExisted)
      );
      return;
    }

    if (!checkUserExistedIsLoading) {
      onSubmit({ email, password, language: currentLanguage });
      saveLoginMethodToCookie(IDP_TYPE.EMAIL);
    }
  };

  const handleEmailFieldFocus = () => {
    setEmailNoticeIsVisible(true);
    setEmailTooltipIsOpened(true);
  };

  const handleEmailFieldBlur = () => {
    setEmailNoticeIsVisible(false);
    setEmailTooltipIsOpened(false);
  };

  const handlePasswordFieldFocus = () => {
    setPasswordNoticeIsVisible(true);
  };

  const handlePasswordFieldBlur = () => {
    setPasswordNoticeIsVisible(false);
  };

  const handleEmailFieldChange: React.ChangeEventHandler<HTMLInputElement> = (
    e
  ) => {
    setEmail(e.currentTarget.value);

    if (!e.currentTarget.value) {
      clearValidationError("email");
      setSubmitIsDisabled(false);
      return;
    }

    if (validateEmail(e.currentTarget.value, usePlusSignInEmailAddress)) {
      checkUserExisted(e.currentTarget.value);
    }
  };

  const handlePasswordFieldChange: React.ChangeEventHandler<HTMLInputElement> = (
    e
  ) => {
    setPassword(e.currentTarget.value);
  };

  const emailAddressFormFieldElement: ReactElement = (
    <FormField
      label={intl.formatMessage(messages.signUpByPasswordFormEmailAddress)}
      required={true}
      error={getValidationError("email")}
      htmlFor="email"
      withPadding
    >
      <Tooltip
        content={intl.formatMessage(messages.signUpByPasswordFormEmailFormat)}
        position={{ xPos: 2, yPos: 2 }}
        opened={emailTooltipIsOpened}
      >
        <div>
          <HeapPHIDecorator protectAttr={["value"]} noWrap>
            <Textfield
              placeholder={intl.formatMessage(
                messages.signUpByPasswordFormEmailAddressPlaceholder
              )}
              outline
              id="email"
              name="email"
              required
              onFocus={handleEmailFieldFocus}
              onBlur={handleEmailFieldBlur}
              onChange={handleEmailFieldChange}
              autoComplete="email"
              value={email}
            />
          </HeapPHIDecorator>
        </div>
      </Tooltip>
    </FormField>
  );

  const emailAddressNoticeElement: ReactElement = (
    <DropdownWrapper isOpen={emailNoticeIsVisible}>
      <Notification type="note" icon={MailIcon}>
        <div className="email-field-notice__content">
          <span>
            {intl.formatMessage(messages.signUpByPasswordFormEmailFieldNotice)}
          </span>
        </div>
      </Notification>
    </DropdownWrapper>
  );

  const passwordFormFieldElement: ReactElement = (
    <FormField
      label={intl.formatMessage(messages.signUpByPasswordFormPasswordLabel)}
      required={true}
      error={getValidationError("password")}
      htmlFor="password"
      withPadding
    >
      <PasswordField
        placeholder={intl.formatMessage(
          messages.signUpByPasswordFormPasswordPlaceholder
        )}
        outline={true}
        id="password"
        name="password"
        required={true}
        onFocus={handlePasswordFieldFocus}
        onBlur={handlePasswordFieldBlur}
        onChange={handlePasswordFieldChange}
        autoComplete="new-password"
      />
    </FormField>
  );

  const passwordNoticeElement: ReactElement = (
    <DropdownWrapper isOpen={passwordNoticeIsVisible}>
      <PasswordNotice
        passwordPolicyComplexity={passwordPolicyComplexityValidationState}
      />
    </DropdownWrapper>
  );

  return (
    <Form noValidate={true} onSubmit={handleSubmit}>
      {emailAddressFormFieldElement}
      {emailAddressNoticeElement}
      {passwordFormFieldElement}
      {passwordNoticeElement}
      <Button type="submit" disabled={submitIsDisabled} raised={true}>
        {intl.formatMessage(messages.signUpByPasswordFormCreateAccount)}
      </Button>
    </Form>
  );
};

export default SignUpByPasswordForm;
