import React, {
  FC,
  useContext,
  useState,
  useRef,
  useEffect,
  useMemo,
  useImperativeHandle,
} from "react";
import classNames from "classnames";
import { defineMessages, useIntl } from "react-intl";
import { sanitize } from "dompurify";
import { EventsContext } from "@app/neva/provider";
import BubbleTail from "@app/neva/components/bubbleTail";
import {
  generatePostbackUserEvent,
  generatePostbackUserEventWithType,
  unescape,
  updatePayloadAttributes,
  wrapTextAddLinks,
} from "@app/neva/helpers";
import {
  MessageButton,
  Rating,
  ConditionCard,
} from "@app/neva/components/message";
import DropdownSelect from "@app/neva/components/dropdownSelect";
import {
  ButtonTypes,
  DropdownItemType,
  EventDataMessageType,
  MessageButtonType,
  RatingItemType,
} from "@app/neva/models";
import "./textMessage.scss";
import { useLinkHandler } from "@app/neva/hooks/useLinkHandler";
import { SANITIZE_OPTIONS } from "@app/neva/models/constants";

interface Props {
  message: EventDataMessageType;
  isLastMessage: boolean;
  isShowAvatar: boolean;
}

const messages = defineMessages({
  chatMessageDialogExternalLink: {
    id: "chatMessageDialogExternalLink",
    defaultMessage: "Open the external resource link",
  },
});

const TextMessage: FC<Props> = ({ message, isLastMessage, isShowAvatar }) => {
  const intl = useIntl();
  const [isDownloadButtonDisabled, setIsDownloadButtonDisabled] = useState(
    false
  );
  const output = useRef<HTMLDivElement>(null);
  const {
    eventsProviderState: { passedOrSkippedFlowComponentsId },
    userEventHandler,
    updateMessagesState,
    setPassedOrSkippedFlowComponentsId,
    textMessageRef,
    quickReplyRef,
  } = useContext(EventsContext);
  const { linkEditorListener } = useLinkHandler();
  const textBubbleClassNames = classNames("text-message__bubble", {
    "text-message__with-logo": isShowAvatar,
  });
  const [selectedDropdownItem, setSelectedDropdownItem] = useState<
    DropdownItemType
  >({
    option: message.dropdown?.selectedValue || "",
    attributePayload: "",
  });
  const [selectedRatingItem, setSelectedRatingItem] = useState<RatingItemType>({
    scoreNumber: message.rating?.selectedValue || -1,
    attributePayload: "",
    ariaLabel: "",
    title: "",
  });
  useImperativeHandle(textMessageRef, () => ({
    disableRatingAndDropdown() {
      setPassedOrSkippedFlowComponentsId(message.id);
      setSelectedRatingItem((prevState) => ({
        ...prevState,
        scoreNumber: -1,
      }));
      setSelectedDropdownItem((prevState) => ({
        ...prevState,
        option: "",
      }));
    },
  }));

  const isDisabledComponentWithButton = useMemo(
    () => passedOrSkippedFlowComponentsId.includes(message.id) || false,
    [message, passedOrSkippedFlowComponentsId]
  );

  useEffect(() => {
    linkEditorListener(output);
  }, []);

  const onButtonClickHandler = (button: MessageButtonType) => {
    quickReplyRef?.current && quickReplyRef.current.removeQuickReplies();
    switch (button.type) {
      case ButtonTypes.DROPDOWN:
        setTimeout(() => {
          userEventHandler(
            generatePostbackUserEvent({
              title: selectedDropdownItem.option,
              payload: updatePayloadAttributes(
                button.payload,
                selectedDropdownItem.attributePayload
              ),
            })
          );
          updateMessagesState(
            message.id,
            selectedDropdownItem.option,
            "dropdown"
          );
          setPassedOrSkippedFlowComponentsId(message.id);
        }, 650);
        break;
      case ButtonTypes.RATING:
        setTimeout(() => {
          userEventHandler(
            generatePostbackUserEventWithType(
              {
                title: selectedRatingItem.title,
                payload: updatePayloadAttributes(
                  button.payload,
                  selectedRatingItem.attributePayload
                ),
              },
              button.type
            )
          );
          updateMessagesState(
            message.id,
            selectedRatingItem.scoreNumber,
            "rating"
          );
          setPassedOrSkippedFlowComponentsId(message.id);
        }, 650);
        break;
    }
  };

  const isButtonDisabled = (button: MessageButtonType): boolean => {
    switch (button.type) {
      case ButtonTypes.DROPDOWN:
        return !selectedDropdownItem.option || isDisabledComponentWithButton;
      case ButtonTypes.RATING:
        return (
          selectedRatingItem.scoreNumber === -1 || isDisabledComponentWithButton
        );
      case ButtonTypes.TRANSCRIPT:
        return isDownloadButtonDisabled;
      default:
        return false;
    }
  };

  const messageButtons = () => {
    return (
      message.buttons?.length &&
      message.buttons.map((button, index) => (
        <MessageButton
          key={index}
          button={button}
          onClick={() => onButtonClickHandler(button)}
          isButtonDisabled={isButtonDisabled(button)}
          setIsDownloadButtonDisabled={setIsDownloadButtonDisabled}
        />
      ))
    );
  };

  const messageDropdown = () => {
    return (
      message.dropdown && (
        <DropdownSelect
          items={message.dropdown.items}
          onSelect={setSelectedDropdownItem}
          selectedValue={[
            selectedDropdownItem.option,
            selectedDropdownItem.attributePayload,
          ]}
          isDisabled={isDisabledComponentWithButton}
        />
      )
    );
  };

  const messageRating = () => {
    return (
      message.rating && (
        <Rating
          items={message.rating.items}
          type={message.rating.type}
          rating={selectedRatingItem.scoreNumber}
          setSelectedRatingItem={setSelectedRatingItem}
          isDisabled={isDisabledComponentWithButton}
        />
      )
    );
  };

  const conditionCard = () => {
    return (
      message.conditionCards && (
        <ConditionCard
          conditionCards={message.conditionCards}
          isLastMessage={isLastMessage}
        />
      )
    );
  };

  return (
    <div ref={output} className="text-message__wrapper">
      <div className={textBubbleClassNames}>
        <div
          className="text-message__text"
          dangerouslySetInnerHTML={{
            __html: sanitize(
              wrapTextAddLinks(
                unescape(message.text as string),
                intl.formatMessage(messages.chatMessageDialogExternalLink)
              ),
              SANITIZE_OPTIONS
            ),
          }}
        />
        {messageDropdown()}
        {messageRating()}
        {conditionCard()}
        {messageButtons()}
      </div>
      {(isShowAvatar || !!message.isSendByUser) && (
        <BubbleTail
          isShowAvatar={isShowAvatar}
          isSendByUser={!!message.isSendByUser}
        />
      )}
    </div>
  );
};

export default TextMessage;
