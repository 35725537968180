import { AddNotificationParameters } from "@app/provider/notification";
import { isExternalServerError } from "@app/provider/error/isExternalServerError";
import { ApolloError } from "@apollo/client";
import { errorMessages } from "@app/provider/error";

export const getExtendedServerErrorNotificationParams = (
  error: ApolloError | undefined
): AddNotificationParameters => {
  const notificationParams: AddNotificationParameters = {
    type: "error",
  };
  if (isExternalServerError(error)) {
    notificationParams.id = errorMessages.externalServiceError.id;
    notificationParams.message = errorMessages.externalServiceError;
    notificationParams.messageType = "text";
  }
  return notificationParams;
};
