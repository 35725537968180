import React, {
  FC,
  ReactElement,
  useContext,
  useEffect,
  useState,
} from "react";
import R from "ramda";
import { Link } from "react-router-dom";
import { defineMessages, useIntl } from "react-intl";

import { LinkButton } from "@natera/platform/lib/components/form";
import { useErrorController } from "@natera/platform/lib/hooks";
import { TransactionStatus } from "@natera/platform/lib/service/session/okta";
import acknowledgements from "@etc/acknowledgements.json";
import { routes } from "@app/routing";
import { isValidLanguageValue, useQuery } from "@app/utils";
import {
  EnrollFactorResponse,
  FACTORS_TO_SHOW,
  IDP_TYPE,
} from "@app/service/user";
import {
  ErrorProvider,
  IntlContext,
  Language,
  MfaErrorProvider,
  NotificationContext,
  ServiceContext,
  UserContext,
} from "@app/provider";
import { LoginStatus } from "@app/provider/user";
import { SignInWithAuthenticationProviders } from "@app/components";
import SpinnerView from "@app/components/spinnerView";
import { LoginForm } from "@app/components/forms";
import VerifyModal from "@app/components/verifyModal";
import "./signIn.scss";
import { useTokenizedLinks } from "@app/hooks";
import { LinkSource } from "@app/hooks/useTokenizedLinks";
import { IntlShape } from "react-intl/src/types";
import { AddNotification } from "@app/provider/notification";
import HighlightWrapper from "@app/components/highlightWrapper/highlightWrapper";
import {
  getLoginMethodFromCookie,
  saveLoginMethodToCookie,
} from "@app/utils/cookiesHelper";

const messages = defineMessages({
  signInNewToNatera: {
    id: "signInNewToNatera",
    defaultMessage: "New to Natera?",
  },
  signInSignUpTitle: {
    id: "signInSignUpTitle",
    defaultMessage: "Sign Up",
  },
  signInAlreadyMember: {
    id: "signInAlreadyMember",
    defaultMessage: "Already a member?",
  },
  signInTitle: {
    id: "signInTitle",
    defaultMessage: "Log In",
  },
  signInTermsOfUse: {
    id: "signInTermsOfUse",
    defaultMessage: "Terms of Use",
  },
  signInPrivacyPolicy: {
    id: "signInPrivacyPolicy",
    defaultMessage: "Privacy Policy",
  },
  signInAuthenticationTypesOr: {
    id: "signInAuthenticationTypesOr",
    defaultMessage: "Or",
  },
  signInNeedAnAccount: {
    id: "signInNeedAnAccount",
    defaultMessage: "Need an account?",
  },
  signInEmail: {
    id: "signInEmail",
    defaultMessage: "Email Address",
  },
  signInPasswordLabel: {
    id: "signInPasswordLabel",
    defaultMessage: "Password",
  },
  signInEmptyFactor: {
    id: "signInEmptyFactor",
    defaultMessage: "We're sorry. Something went wrong.",
  },
  signInForgotPassword: {
    id: "signInForgotPassword",
    defaultMessage: "Forgot password?",
  },
  signInForgotPasswordButton: {
    id: "signInForgotPasswordButton",
    defaultMessage: "Forgot Password?",
  },
  signInNeedAccount: {
    id: "signInNeedAccount",
    defaultMessage: "Already have an account?",
  },
  signInAcknowledgements: {
    id: "signInAcknowledgements",
    defaultMessage:
      "By continuing, you are accepting our {termsOfUse} and {privacyPolicy}",
  },
  signInIncorrectCredentials: {
    id: "signInIncorrectCredentials",
    defaultMessage: "Incorrect email or password",
  },
  signInTryAgain: {
    id: "signInTryAgain",
    defaultMessage:
      "Please try again. If you have not activated your account, please check your email or request a new activation link.",
  },
  signInTryAgainAccountActivated: {
    id: "signInTryAgainAccountActivated",
    defaultMessage:
      "Please try again using a different email and password combination.",
  },
  signInAttemptsRemain: {
    id: "signInAttemptsRemain",
    defaultMessage: "Attempts Remaining — {attempts}",
  },
  signInMaxAttemptsText: {
    id: "signInMaxAttemptsText",
    defaultMessage:
      "After 5 consecutive unsuccessful login attempts, your account will be locked",
  },
  signInAccountLocked: {
    id: "signInAccountLocked",
    defaultMessage: "Account Locked",
  },
  signInAccountLockedText: {
    id: "signInAccountLockedText",
    defaultMessage:
      "Your account has been locked due to multiple incorrect attempts.",
  },
  signInUnlockAccount: {
    id: "signInUnlockAccount",
    defaultMessage: "Unlock Account",
  },
  userStatusInactive: {
    id: "userStatusInactive",
    defaultMessage: "Your account has not been activated.",
  },
  signInActivationResend: {
    id: "signInActivationResend",
    defaultMessage: "Resend activation email",
  },
  highlightedTooltipDescriptionForm: {
    id: "highlightedTooltipDescriptionForm",
    defaultMessage: "You previously logged in with your Natera account.",
  },
});

export enum OktaErrorCodes {
  AUTH_ERROR = "E0000004",
}

export const ATTEMPTS_COUNT_TO_SHOW = 2;

interface ErrorResponse {
  errorSummary: string;
  errorCode: string;
  status?: string;
}

const getFirstFilteredFactor = (
  factors?: EnrollFactorResponse[]
): EnrollFactorResponse | undefined => {
  if (!factors) return;
  const filteredFactorList = R.filter(
    (factor) => factor.factorType in FACTORS_TO_SHOW,
    factors
  );
  return filteredFactorList[0];
};

export const handleFactors = (
  factors: EnrollFactorResponse[],
  intl: IntlShape,
  addNotification: AddNotification,
  setFactor: React.Dispatch<
    React.SetStateAction<EnrollFactorResponse | undefined>
  >
): void => {
  const selectedFactor = getFirstFilteredFactor(factors);
  if (!selectedFactor) {
    addNotification({
      message: intl.formatMessage(messages.signInEmptyFactor),
      type: "error",
    });
  } else {
    setFactor(selectedFactor);
  }
};

const SignIn: FC = () => {
  const intl = useIntl();
  const query = useQuery();
  const token = query.get("token");
  let lang: string | null = null;
  const { loadProfile, isLoading, logUserLogin, handleUserLogin } = useContext(
    UserContext
  );
  const { changeLanguage, currentLanguage } = useContext(IntlContext);

  const [isLoginPending, setIsLoginPending] = useState(false);
  const [factor, setFactor] = useState<EnrollFactorResponse>();
  const [tokenSource, setTokenSource] = useState<LinkSource>();

  const { addNotification, clear } = useContext(NotificationContext);
  const { sessionService } = useContext(ServiceContext);
  const errorController = useErrorController();
  const { getTokenSource } = useTokenizedLinks();

  const getLinkTokenSource = React.useCallback(async () => {
    if (token) {
      const data = await getTokenSource(token);
      lang = data?.language ?? lang;

      if (lang && currentLanguage !== lang && isValidLanguageValue(lang)) {
        changeLanguage(lang as Language);
      }
      setTokenSource(data?.source);
    } else {
      setTokenSource(undefined);
    }
  }, [token]);

  React.useEffect(() => {
    getLinkTokenSource();
  }, [getLinkTokenSource]);

  const emailVerified = query.get("email_verified");
  lang = query.get("lang") ?? lang;

  useEffect(() => {
    if (lang && currentLanguage !== lang && isValidLanguageValue(lang)) {
      changeLanguage(lang as Language);
    }
  }, []);

  const getResendActivationLink = () => (
    <Link to={routes.resendActivationLink}>
      {intl.formatMessage(messages.signInActivationResend)}
    </Link>
  );

  const getResetPasswordLink = () => (
    <Link to={routes.resetPassword}>
      {intl.formatMessage(messages.signInForgotPassword)}
    </Link>
  );

  const addNotificationAttemptsRemaining = (
    attemptsLeft?: number,
    isAccountActivated?: boolean
  ) => {
    addNotification({
      title: intl.formatMessage(messages.signInIncorrectCredentials),
      message: (
        <>
          <div>
            {intl.formatMessage(messages.signInAttemptsRemain, {
              attempts: attemptsLeft,
            })}
          </div>
          <div>{intl.formatMessage(messages.signInMaxAttemptsText)}</div>
        </>
      ),
      actions: isAccountActivated
        ? getResetPasswordLink()
        : getResendActivationLink(),
      type: "warning",
    });
  };

  const addNotificationTryAgain = (isAccountActivated?: boolean) => {
    addNotification({
      title: intl.formatMessage(messages.signInIncorrectCredentials),
      message: isAccountActivated
        ? intl.formatMessage(messages.signInTryAgainAccountActivated)
        : intl.formatMessage(messages.signInTryAgain),
      actions: isAccountActivated
        ? getResetPasswordLink()
        : getResendActivationLink(),
      type: "warning",
    });
  };

  const handleLogUserLogin = async (email: string) => {
    try {
      const data = await logUserLogin(email, LoginStatus.FAIL, token);

      if (!data) {
        throw new Error();
      }

      const { attemptsLeft, isAccountActivated } = data;

      if (attemptsLeft && attemptsLeft <= ATTEMPTS_COUNT_TO_SHOW) {
        addNotificationAttemptsRemaining(attemptsLeft, isAccountActivated);
      } else {
        addNotificationTryAgain(isAccountActivated);
      }
    } catch (e) {
      addNotification({
        title: messages.signInIncorrectCredentials,
        type: "warning",
        messageType: "text",
      });
    }
  };

  const handleError = async (err: ErrorResponse, email: string) => {
    switch (err.errorCode) {
      case OktaErrorCodes.AUTH_ERROR:
        await handleLogUserLogin(email);
        break;
      default:
        if (err.status === TransactionStatus.LOCKED_OUT) {
          const actions = (
            <Link to={{ pathname: routes.unlock, state: { email } }}>
              {intl.formatMessage(messages.signInUnlockAccount)}
            </Link>
          );
          addNotification({
            title: intl.formatMessage(messages.signInAccountLocked),
            message: intl.formatMessage(messages.signInAccountLockedText),
            actions,
            type: "error",
          });
        } else {
          addNotification({
            type: "error",
          });
        }
        break;
    }
  };

  const handleSubmit = async (props: { email: string; password: string }) => {
    setIsLoginPending(true);
    clear();
    try {
      if (await sessionService.getToken()) {
        await sessionService.clearToken();
      }

      const transaction = await sessionService.login(props);
      const status = transaction.transaction.status;
      if (status === TransactionStatus.LOCKED_OUT) {
        throw { status };
      }
      await logUserLogin(props.email, LoginStatus.SUCCESS, token);
      const factors = transaction.transaction.factors as EnrollFactorResponse[];
      if (factors && factors?.length > 0) {
        handleFactors(factors, intl, addNotification, setFactor);
      } else {
        saveLoginMethodToCookie(IDP_TYPE.EMAIL);
        await handleUserLogin();
        await loadProfile();
      }
    } catch (error) {
      await handleError(error, props.email);
    } finally {
      setIsLoginPending(false);
    }
  };
  const title: ReactElement = (
    <section className="title__container">
      <span>{intl.formatMessage(messages.signInAlreadyMember)}</span>
      <h1>{intl.formatMessage(messages.signInTitle)}</h1>
    </section>
  );

  const conditions: ReactElement = (
    <section className="conditions__container">
      <span className="acknowledgements__text">
        {intl.formatMessage(messages.signInAcknowledgements, {
          termsOfUse: (
            <a
              href={acknowledgements.links.termsOfUse}
              key="termsOfUse"
              target="_blank"
              rel="noreferrer"
            >
              {intl.formatMessage(messages.signInTermsOfUse)}
            </a>
          ),
          privacyPolicy: (
            <a
              href={acknowledgements.links.privacyPolicy}
              key="privacyPolicy"
              target="_blank"
              rel="noreferrer"
            >
              {intl.formatMessage(messages.signInPrivacyPolicy)}
            </a>
          ),
        })}
      </span>
    </section>
  );

  const authenticationProviders: ReactElement = (
    <SignInWithAuthenticationProviders
      invite={token}
      tokenSource={tokenSource}
      isSignInFlow
    />
  );

  const separator: ReactElement = (
    <div className="separator__container">
      <hr />
      <span>{intl.formatMessage(messages.signInAuthenticationTypesOr)}</span>
      <hr />
    </div>
  );

  const isHighlightedForm = IDP_TYPE.EMAIL === getLoginMethodFromCookie();

  const renderPasswordForm = () => (
    <HighlightWrapper
      isHighlighted={isHighlightedForm}
      highlightedElem="form"
      tooltipDescription={intl.formatMessage(
        messages.highlightedTooltipDescriptionForm
      )}
      tooltipPosition="top"
    >
      <section className="form__container">
        <ErrorProvider controller={errorController}>
          <LoginForm
            setValidationError={errorController.setValidationError}
            clearValidationError={errorController.clearValidationError}
            clearErrors={errorController.clearErrors}
            isLoading={isLoginPending}
            onSubmit={handleSubmit}
            prefilledEmail={emailVerified || undefined}
          />
        </ErrorProvider>
      </section>
    </HighlightWrapper>
  );

  const forgotPassword: ReactElement = (
    <section className="forgot-password__container">
      <LinkButton to={routes.resetPassword} outlined tabIndex={-1}>
        {intl.formatMessage(messages.signInForgotPasswordButton)}
      </LinkButton>
    </section>
  );

  const signUp: ReactElement = (
    <section className="to-sign-up__container">
      <span>{intl.formatMessage(messages.signInNeedAnAccount)} </span>
      <Link to={routes.signUp}>
        {intl.formatMessage(messages.signInSignUpTitle)}
      </Link>
    </section>
  );

  return (
    <MfaErrorProvider>
      {factor ? (
        <VerifyModal factor={factor} setFactor={setFactor} />
      ) : (
        <article className="sign-in__container">
          <SpinnerView isLoading={isLoading || isLoginPending} />
          {title}
          {authenticationProviders}
          {conditions}
          {separator}
          {renderPasswordForm()}
          {forgotPassword}
          {signUp}
        </article>
      )}
    </MfaErrorProvider>
  );
};

export default SignIn;
