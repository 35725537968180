import { TestCardStatus } from "@app/provider/testData/types";
import { IntlShape, defineMessages } from "react-intl";

const messages = defineMessages({
  testCardStatusUpcomingTest: {
    id: "testCardStatusUpcomingTest",
    defaultMessage: "Upcoming Test",
  },
  testCardStatusTestOrdered: {
    id: "testCardStatusTestOrdered",
    defaultMessage: "Test Ordered",
  },
  testCardStatusKitShipped: {
    id: "testCardStatusKitShipped",
    defaultMessage: "Kit Shipped",
  },
  testCardStatusAppointmentRequested: {
    id: "testCardStatusAppointmentRequested",
    defaultMessage: "Appt. Requested",
  },
  testCardStatusAppointmentConfirmed: {
    id: "testCardStatusAppointmentConfirmed",
    defaultMessage: "Appt. Confirmed",
  },
  testCardStatusSampleReceived: {
    id: "testCardStatusSampleReceived",
    defaultMessage: "Sample Received",
  },
  testCardStatusSamplesReceived: {
    id: "testCardStatusSamplesReceived",
    defaultMessage: "Samples Received",
  },
  testCardStatusResultReady: {
    id: "testCardStatusResultReady",
    defaultMessage: "Result Ready",
  },
  testCardStatusTestComplete: {
    id: "testCardStatusTestComplete",
    defaultMessage: "Test Complete",
  },
  testCardStatusCancelledTest: {
    id: "testCardStatusCancelledTest",
    defaultMessage: "Cancelled Test",
  },
  testCardStatusExpiredTest: {
    id: "testCardStatusExpiredTest",
    defaultMessage: "Expired Test",
  },
  testCardStatusRedrawNeeded: {
    id: "testCardStatusRedrawNeeded",
    defaultMessage: "Redraw Needed",
  },
  testCardStatusNewTissueNeeded: {
    id: "testCardStatusNewTissueNeeded",
    defaultMessage: "Tissue Needed",
  },
});

export const getStatusText = (
  status: TestCardStatus | undefined,
  intl: IntlShape
): string | undefined => {
  switch (status) {
    case TestCardStatus.TEST_ORDERED:
      return intl.formatMessage(messages.testCardStatusTestOrdered);
    case TestCardStatus.UPCOMING_TEST:
      return intl.formatMessage(messages.testCardStatusUpcomingTest);
    case TestCardStatus.KIT_SHIPPED:
      return intl.formatMessage(messages.testCardStatusKitShipped);
    case TestCardStatus.APPT_REQUESTED:
      return intl.formatMessage(messages.testCardStatusAppointmentRequested);
    case TestCardStatus.APPT_CONFIRMED:
      return intl.formatMessage(messages.testCardStatusAppointmentConfirmed);
    case TestCardStatus.SAMPLE_RECEIVED:
      return intl.formatMessage(messages.testCardStatusSampleReceived);
    case TestCardStatus.SAMPLES_RECEIVED:
      return intl.formatMessage(messages.testCardStatusSamplesReceived);
    case TestCardStatus.RESULT_READY:
      return intl.formatMessage(messages.testCardStatusResultReady);
    case TestCardStatus.TEST_COMPLETE:
      return intl.formatMessage(messages.testCardStatusTestComplete);
    case TestCardStatus.CANCELLED_TEST:
      return intl.formatMessage(messages.testCardStatusCancelledTest);
    case TestCardStatus.EXPIRED_TEST:
      return intl.formatMessage(messages.testCardStatusExpiredTest);
    case TestCardStatus.REDRAW_NEEDED:
      return intl.formatMessage(messages.testCardStatusRedrawNeeded);
    case TestCardStatus.NEW_TISSUE_NEEDED:
      return intl.formatMessage(messages.testCardStatusNewTissueNeeded);
    default:
      return undefined;
  }
};
