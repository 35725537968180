import React, { FC } from "react";
import { defineMessages, useIntl } from "react-intl";

import NoContentView from "../../../private/noContentView";
import { ConfigContext } from "@app/provider";
import { HEAP_EVENTS } from "@app/provider/types";
import { LinkHeap } from "@app/components";

const messages = defineMessages({
  createBloodDrawFailedScreenTitle: {
    id: "createBloodDrawFailedScreenTitle",
    defaultMessage: "We're sorry.",
  },
  createBloodDrawFailedScreenDescription: {
    id: "createBloodDrawFailedScreenDescription",
    defaultMessage:
      "We are unable to submit your appointment{br}request at this time.",
  },
  createBloodDrawFailedScreenContact: {
    id: "createBloodDrawFailedScreenContact",
    defaultMessage: "Any questions? {contactUs}",
  },
  createBloodDrawFailedScreenContactUs: {
    id: "createBloodDrawFailedScreenContactUs",
    defaultMessage: "Contact us",
  },
});

const CreateBloodDrawFailedScreen: FC = () => {
  const intl = useIntl();

  const { config } = React.useContext(ConfigContext);

  return (
    <NoContentView
      title={intl.formatMessage(messages.createBloodDrawFailedScreenTitle)}
      message={intl.formatMessage(
        messages.createBloodDrawFailedScreenDescription,
        {
          br: <br />,
        }
      )}
      contactInfo={intl.formatMessage(
        messages.createBloodDrawFailedScreenContact,
        {
          contactUs: (
            <LinkHeap
              target="_blank"
              key="privacyPolicy"
              rel="noopener noreferrer"
              to={{ pathname: config.links.CONTACT_US }}
              heapEventName={HEAP_EVENTS.upp_click_contactnatera}
            >
              {intl.formatMessage(
                messages.createBloodDrawFailedScreenContactUs
              )}
            </LinkHeap>
          ),
        }
      )}
    />
  );
};

export default CreateBloodDrawFailedScreen;
