import { defineMessages } from "react-intl";

export const messages = defineMessages({
  testCardDescOrderedNatera: {
    id: "testCardDescOrderedNatera",
    defaultMessage:
      "Your test has been ordered. Someone from Natera will reach out to you.",
  },
  testProsperaCardDescOrderedNatera: {
    id: "testProsperaCardDescOrderedNatera",
    defaultMessage:
      "Your test has been ordered. Someone from Natera will reach out to help schedule your blood draw.",
  },
  testCardSignateraOrderedByClinicFirstTestDesc: {
    id: "testCardSignateraOrderedByClinicFirstTestDesc",
    defaultMessage:
      "Your test has been ordered. Natera is also in the process of getting a tissue sample for this test, which may take a few weeks. We will let you know when your samples are received at the lab.",
  },
  testCardSignateraOrderedByClinicSubsequentTestDesc: {
    id: "testCardSignateraOrderedByClinicSubsequentTestDesc",
    defaultMessage:
      "Your test has been ordered. We will let you know when your sample is received at the lab.",
  },
  testCardDescOrderedConnectDraft: {
    id: "testCardDescOrderedConnectDraft",
    defaultMessage:
      "Don't forget to complete your order. You will receive a confirmation once it has been submitted.",
  },
  testCardDescOrderedConnectPatientSigned: {
    id: "testCardDescOrderedConnectPatientSigned",
    defaultMessage:
      "Once the lab collects the blood sample, it usually takes 3-5 days for it to arrive.",
  },
  testCardDescOrderedConnect: {
    id: "testCardDescOrderedConnect",
    defaultMessage:
      "Your sample is on its way to the lab. It usually takes 3-5 days for it to arrive.",
  },
  testCardDescOrderedClinic: {
    id: "testCardDescOrderedClinic",
    defaultMessage:
      "Your test has been ordered. We will let you know when your sample is received at the lab.",
  },
  testCardBloodDrawTestOrdered: {
    id: "testCardBloodDrawTestOrdered",
    defaultMessage:
      "Your test has been ordered. Please schedule your blood draw.",
  },
  testCardOncologyManagedByClinicTestOrdered: {
    id: "testCardOncologyManagedByClinicTestOrdered",
    defaultMessage:
      "Your test has been ordered. Natera is also in the process of getting a tissue sample for this test, which may take a few weeks. We will let you know when your samples are received at the lab.",
  },
  testCardOncologyManagedByNateraTestOrdered: {
    id: "testCardOncologyManagedByNateraTestOrdered",
    defaultMessage:
      "Your test has been ordered. Please schedule your blood draw.",
  },
  testCardOncologyManagedByNateraTestOrderedSampleCollectionDate: {
    id: "testCardOncologyManagedByNateraTestOrderedSampleCollectionDate",
    defaultMessage:
      "Your test has been ordered. Please schedule your blood draw for a date before {sampleCollectionDate}",
  },
  testCardDescShipped: {
    id: "testCardDescShipped",
    defaultMessage: "We have shipped your kit.",
  },
  testCardDescSubmitKit: {
    id: "testCardDescSubmitKit",
    defaultMessage: "Please submit your saliva sample when the kit arrives.",
  },
  testCardDescApptRequested: {
    id: "testCardDescApptRequested",
    defaultMessage:
      "Your appointment request has been submitted. The mobile clinic will call you to confirm date and time.",
  },
  testCardOncologyApptConfirmedDesc: {
    id: "testCardOncologyApptConfirmedDesc",
    defaultMessage:
      "Your appointment is confirmed. The mobile clinic will call you to discuss next steps. Natera is also in the process of getting a tissue sample for this test, which may take a few weeks.",
  },
  testCardDescApptConfirmed: {
    id: "testCardDescApptConfirmed",
    defaultMessage:
      "Your appointment is confirmed. The mobile clinic will call you to discuss next steps.",
  },
  testCardDescSample: {
    id: "testCardDescSample",
    defaultMessage:
      "Results typically come back within 3-4 weeks. We will notify you when your results are ready.",
  },
  testCardDefaultDescSampleReceivedEmpower: {
    id: "testCardDefaultDescSampleReceivedEmpower",
    defaultMessage:
      "Results typically come back within 2-3 weeks. We will notify you when your results are ready.",
  },
  testCardDefaultDescSampleReceivedHorizon: {
    id: "testCardDefaultDescSampleReceivedHorizon",
    defaultMessage:
      "Results typically come back within 2-3 weeks. We will notify you when your results are ready.",
  },
  testCardDefaultDescSampleReceivedPanorama: {
    id: "testCardDefaultDescSampleReceivedPanorama",
    defaultMessage:
      "Results typically come back within 1-2 weeks. We will notify you when your results are ready.",
  },
  testCardDefaultDescSampleReceivedProspera: {
    id: "testCardDefaultDescSampleReceivedProspera",
    defaultMessage:
      "Results typically come back within 3-4 days. We will notify you when your results are ready.\n",
  },
  testCardDescSampleReceived: {
    id: "testCardDescSampleReceived",
    defaultMessage:
      "Based on the typical processing time, we estimate your results will be ready by {date}. We will notify you when your results are ready.",
  },
  testCardDescResultReady: {
    id: "testCardDescResultReady",
    defaultMessage:
      "Your results are ready. Our genetic counselors are available to discuss your results and next steps.",
  },
  testCardDescResultReadyReview: {
    id: "testCardDescResultReadyReview",
    defaultMessage: "Your test results are ready for review.",
  },
  testCardDescResultReadyReviewEmpowerStat: {
    id: "testCardDescResultReadyReviewEmpowerStat",
    defaultMessage:
      "Your results are ready. These are preliminary or partial results. Based on typical processing time, we estimate your full results will be ready by {date}.",
  },
  testCardDescComplete: {
    id: "testCardDescComplete",
    defaultMessage:
      "Contact your physician to schedule an appointment to discuss your result.",
  },
  testCardDescCancelled: {
    id: "testCardDescCancelled",
    defaultMessage: "Your test has been cancelled.",
  },
  testCardDescRedrawNeeded: {
    id: "testCardDescRedrawNeeded",
    defaultMessage:
      "Natera needs an additional blood sample for this test. We will let you know when your sample has arrived at the lab.",
  },
  testCardDescAdditionalTissueNeeded: {
    id: "testCardDescAdditionalTissueNeeded",
    defaultMessage:
      "Natera needs an additional tissue sample to perform this test. Our lab team is working with your healthcare team to get an additional tissue sample.",
  },
  testCardSecondaryDescCompassionateCare: {
    id: "testCardSecondaryDescCompassionateCare",
    defaultMessage:
      "If you meet certain income criteria*, you could be eligible for a reduced patient responsibility rate.",
  },
  testCardSecondaryDescNoteCompassionateCare: {
    id: "testCardSecondaryDescNoteCompassionateCare",
    defaultMessage: "*Based on Federal poverty thresholds.",
  },
  testCardSecondaryTitleCompassionateCare: {
    id: "testCardSecondaryTitleCompassionateCare",
    defaultMessage: "Financial access programs",
  },
  salivaSecondaryActionCardTitle: {
    id: "salivaSecondaryActionCardTitle",
    defaultMessage: "Saliva Sample Collection",
  },
  salivaSecondaryActionCardDesc: {
    id: "salivaSecondaryActionCardDesc",
    defaultMessage: "Learn more about the saliva sample collection process",
  },
  prosperaKidneySecondaryActionCardTitle: {
    id: "prosperaKidneySecondaryActionCardTitle",
    defaultMessage: "About the Prospera\u2122 Kidney test",
  },
  prosperaDNASecondaryActionCardDesc: {
    id: "prosperaKidneySecondaryActionCardDesc",
    defaultMessage:
      "Measures the amount of donor DNA in your blood to assess risk for rejection.",
  },
  prosperaHeartSecondaryActionCardTitle: {
    id: "prosperaHeartSecondaryActionCardTitle",
    defaultMessage: "About the Prospera\u2122 Heart test",
  },
  prosperaLiverSecondaryActionCardTitle: {
    id: "prosperaLiverSecondaryActionCardTitle",
    defaultMessage: "About the Prospera\u2122 Liver test",
  },
  prosperaLungSecondaryActionCardTitle: {
    id: "prosperaLungSecondaryActionCardTitle",
    defaultMessage: "About the Prospera\u2122 Lung test",
  },
  prosperaOtherSecondaryActionCardTitle: {
    id: "prosperaOtherSecondaryActionCardTitle",
    defaultMessage: "About the Prospera\u2122 test",
  },
  renasightKidneySecondaryActionCardTitle: {
    id: "renasightKidneySecondaryActionCardTitle",
    defaultMessage: "About the Renasight\u2122 kidney gene panel",
  },
  renasightKidneySecondaryActionCardDesc: {
    id: "renasightKidneySecondaryActionCardDesc",
    defaultMessage:
      "Screens 385 genes for genetic variants linked with kidney disease.",
  },
  alteraInfoCardTitle: {
    id: "alteraInfoCardTitle",
    defaultMessage: "About Altera\u2122 Tumor Genomic Profile Test",
  },
  alteraInfoCardDesc: {
    id: "alteraInfoCardDesc",
    defaultMessage:
      "Altera™ is a comprehensive genomic profiling test which provides clinically relevant biomarkers to help guide treatment selection for patients with advanced stage cancers.",
  },
  signateraInfoCardTitle: {
    id: "signateraInfoCardTitle",
    defaultMessage: "About Signatera™ Molecular Residual Disease Test",
  },
  signateraInfoCardDesc: {
    id: "signateraInfoCardDesc",
    defaultMessage:
      "Signatera™ is a highly sensitive and personalized molecular residual disease assay (MRD) using circulating tumor DNA (ctDNA), custom designed for each patient to help identify relapse earlier than\n" +
      "standard of care tools.",
  },
  empowerInfoCardTitle: {
    id: "empowerInfoCardTitle",
    defaultMessage: "About Empower\u2122 hereditary cancer screening",
  },
  empowerInfoCardDesc: {
    id: "empowerInfoCardDesc",
    defaultMessage:
      "Empower™ screens for genes associated with increased risk for developing hereditary cancers.",
  },
  horizonInfoCardTitle: {
    id: "horizonInfoCardTitle",
    defaultMessage: "About Horizon\u2122 carrier screening",
  },
  horizonInfoCardDesc: {
    id: "horizonInfoCardDesc",
    defaultMessage:
      "Horizon™ can help couples determine the risk of passing on serious, inherited genetic conditions to their child.",
  },
  panoramaInfoCardTitle: {
    id: "panoramaInfoCardTitle",
    defaultMessage: "About Panorama\u2122 Noninvasive Prenatal Testing (NIPT)",
  },
  panoramaInfoCardDesc: {
    id: "panoramaInfoCardDesc",
    defaultMessage:
      "Panorama™ screens a pregnant person for common chromosomal conditions that affect a baby's health, such as Down Syndrome.",
  },
  vistaraInfoCardTitle: {
    id: "vistaraInfoCardTitle",
    defaultMessage: "About Vistara\u2122 single-gene NIPT",
  },
  vistaraInfoCardDesc: {
    id: "vistaraInfoCardDesc",
    defaultMessage:
      "Vistara™ screens for single-gene conditions in your baby that could benefit from early intervention and might otherwise go undetected.",
  },
  geneticTestingInfoCardTitle: {
    id: "geneticTestingInfoCardTitle",
    defaultMessage: "About genetic testing",
  },
  pricingBillingInfoCardTitle: {
    id: "pricingBillingInfoCardTitle",
    defaultMessage: "Pricing and Payment Options",
  },
  geneticTestingInfoCardDesc: {
    id: "geneticTestingInfoCardDesc",
    defaultMessage:
      "Our genetic tests support the entire spectrum of women's health needs. Find more information about the tests your provider ordered here.",
  },
  pricingBillingInfoCardDesc: {
    id: "pricingBillingInfoCardDesc",
    defaultMessage:
      "Find information about in-network plans, personalized cost estimates, financial access programs, and where to pay your bill.",
  },
  testCardSecondaryDescNoteEmpty: {
    id: "testCardSecondaryDescNoteEmpty",
    defaultMessage: "",
  },
  testCardDescExpired: {
    id: "testCardDescExpired",
    defaultMessage: "Your test has expired.",
  },
  panoramaHorizonTitle: {
    id: "panoramaPlusHorizonTitle",
    defaultMessage: "About Panorama NIPT and Horizon Carrier Screening",
  },
  panoramaHorizonDesc: {
    id: "panoramaPlusHorizonDesc",
    defaultMessage:
      "Panorama screens for conditions that can affect a baby’s health, and Horizon helps couples determine their risk of passing on an inherited genetic condition.",
  },
});
