import React, { FC, useState } from "react";
import { Switch, Route, useHistory } from "react-router-dom";
import { routes } from "@app/routing";
import {
  OrderContext,
  SimpleOrderContext,
  NotificationContext,
} from "@app/provider";
import SimpleOrderPage from "@app/pages/private/simpleOrder/simpleOrderPage";
import AboutYourTestPage from "@app/pages/private/simpleOrder/aboutYourTest";
import { SimpleOrderWorkflowState } from "@app/provider/order";
import TestNotFoundModal from "@app/components/testNotFoundDialog";
import { useDialog } from "@natera/platform/lib/hooks";
import { getTestNameForHeap } from "@app/utils";
import { SpinnerView } from "@app/components";
import { SimpleOrderStepperProvider } from "@app/provider/simpleOrder/SimpleOrderStepperProvider";
import { getSimpleOrderWorkflow } from "@app/utils/orderHelpers";

const SimpleOrderRouting: FC = () => {
  const history = useHistory();
  const { addNotification } = React.useContext(NotificationContext);
  const { getOrder } = React.useContext(OrderContext);
  const { markOrderAsAccessedByPatient } = React.useContext(SimpleOrderContext);

  const [displaySimpleOrderContent, setDisplaySimpleOrderContent] = useState(
    false
  );

  const testNotFoundDialog = useDialog(TestNotFoundModal);

  React.useEffect(() => {
    (async () => {
      const order = await getOrder();

      if (!order) {
        testNotFoundDialog.open({});
        return;
      }

      try {
        const simpleOrderWorkflow = getSimpleOrderWorkflow(order.workflows);

        if (!simpleOrderWorkflow) {
          throw new Error();
        }

        switch (simpleOrderWorkflow.simpleOrderState) {
          case SimpleOrderWorkflowState.COMMUNICATION_SENT: {
            await markOrderAsAccessedByPatient(
              order.uid,
              simpleOrderWorkflow.uid,
              getTestNameForHeap(order),
              order.clinic?.limsId?.toString()
            );
            break;
          }
          case SimpleOrderWorkflowState.PATIENT_ACCESSED: {
            break;
          }
          default:
            history.push(routes.home);
        }

        setDisplaySimpleOrderContent(true);
      } catch (error) {
        addNotification({
          type: "error",
        });
      }
    })();
  }, []);

  return (
    <SimpleOrderStepperProvider>
      {testNotFoundDialog.getDialog()}
      {displaySimpleOrderContent ? (
        <Switch>
          <Route path={routes.aboutTest} component={AboutYourTestPage} />
          <Route exact path={routes.simpleOrder} component={SimpleOrderPage} />
        </Switch>
      ) : (
        <SpinnerView isLoading={!displaySimpleOrderContent} />
      )}
    </SimpleOrderStepperProvider>
  );
};

export default SimpleOrderRouting;
