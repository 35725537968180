import React, { FC, useContext, useState } from "react";
import { defineMessages, useIntl } from "react-intl";
import "./renewalPopUp.scss";
import useIsMobileDimension from "../../hooks/useIsMobileDimension";
import { HEAP_EVENTS } from "@app/provider/types";
import { NotificationContext, UserContext } from "@app/provider";
import Dialog from "@natera/material/lib/dialog";
import Button from "@natera/material/lib/button";
import ResponceImage from "@assets/images/renewalPatientResponce.png";

const messages = defineMessages({
  renewalPopUpTitle: {
    id: "renewalPopUpTitle",
    defaultMessage:
      "Are you interested in discussing renewal with your healthcare provider?",
  },
  renewalPopUpAgreeButton: {
    id: "renewalPopUpAgreeButton",
    defaultMessage: "Yes, please contact my healthcare provider on my behalf.",
  },
  renewalPopUpDisagreeButton: {
    id: "renewalPopUpDisagreeButton",
    defaultMessage:
      "No, I’m not interested in discussing renewal at this time.",
  },
  renewalPopUpGratitude: {
    id: "renewalPopUpGratitude",
    defaultMessage: "Thanks for your response",
  },
});

interface RenewalPopUpProps {
  onCloseDialog: () => void;
  limsId?: number;
  orderUid?: string;
}

const RenewalPopUp: FC<RenewalPopUpProps> = ({
  onCloseDialog,
  limsId,
  orderUid,
}) => {
  const intl = useIntl();
  const isMobile = useIsMobileDimension();
  const { uppUser } = useContext(UserContext);
  const { clear } = useContext(NotificationContext);

  const [activeDialog, setActiveDialog] = useState<"first" | "second" | null>(
    "first"
  );
  const [closingDialog, setClosingDialog] = useState<"first" | "second" | null>(
    null
  );
  const animationDuration = 600;

  React.useEffect(() => {
    if (isMobile && activeDialog) {
      const element = document.querySelector(`.popUp-dialog.mobile`);
      if (element) {
        element.classList.add("animate-up");
      }
    }
  }, [isMobile, activeDialog]);

  const handleCloseFirstDialog = () => {
    setClosingDialog("first");
    setTimeout(() => {
      setActiveDialog("second");
      setClosingDialog(null);
    }, animationDuration);
  };

  const handleCloseSecondDialog = () => {
    setClosingDialog("second");
    setTimeout(() => {
      clear();
      onCloseDialog();
    }, animationDuration);
  };

  const handleAgree = () => {
    heap.track(HEAP_EVENTS.upp_renewal_signatera_yes_interested, {
      identity: uppUser?.patientUID,
      lims_clinic_id: limsId,
      order_uid: orderUid,
    });
    handleCloseFirstDialog();
  };

  const handleDisagree = () => {
    heap.track(HEAP_EVENTS.upp_renewal_signatera_not_interested, {
      identity: uppUser?.patientUID,
      lims_clinic_id: limsId,
      order_uid: orderUid,
    });
    handleCloseFirstDialog();
  };

  return (
    <>
      {activeDialog === "first" && (
        <Dialog
          title={""}
          showCrossButton
          onClose={onCloseDialog}
          className={`popUp-dialog ${isMobile ? "mobile" : ""} ${
            closingDialog === "first" ? "animate-down" : ""
          }`}
        >
          <div className="popUp-dialog__content">
            <h3 className="popUp-dialog__content__header">
              {intl.formatMessage(messages.renewalPopUpTitle)}
            </h3>
            <Button
              className="popUp-dialog__content__button"
              aria-label={intl.formatMessage(messages.renewalPopUpAgreeButton)}
              onClick={handleAgree}
            >
              {intl.formatMessage(messages.renewalPopUpAgreeButton)}
            </Button>
            <Button
              className="popUp-dialog__content__button"
              aria-label={intl.formatMessage(
                messages.renewalPopUpDisagreeButton
              )}
              onClick={handleDisagree}
            >
              {intl.formatMessage(messages.renewalPopUpDisagreeButton)}
            </Button>
          </div>
        </Dialog>
      )}

      {activeDialog === "second" && (
        <Dialog
          title={""}
          showCrossButton
          onClose={handleCloseSecondDialog}
          className={`popUp-dialog ${isMobile ? "mobile" : ""} ${
            closingDialog === "second" ? "animate-down" : ""
          }`}
        >
          <div className="popUp-dialog__content">
            <img
              src={ResponceImage}
              alt={intl.formatMessage(messages.renewalPopUpGratitude)}
            />
            <h3>{intl.formatMessage(messages.renewalPopUpGratitude)}</h3>
          </div>
        </Dialog>
      )}
    </>
  );
};

export default RenewalPopUp;
