import React, { FC, createContext, useEffect, useMemo, useState } from "react";
import { useLazyQuery } from "@apollo/client";
import OrderRenewalService, {
  RenewalInfoResponse,
  StartRenewalWorkflowResponse,
} from "@app/service/orderRenewal";

import RenewalInfoDialog from "@app/components/renewalInfoDialog/renewalInfoDialog";
import RenewalPopUp from "@app/components/renewalPopUp/renewalPopUp";
import { useDialog } from "@natera/platform/lib/hooks";

type GetRenewalInfoProps = {
  orderUid?: string;
  token?: string;
};
export interface OrderRenewalController {
  isLoading: boolean;
  startRenewalWorkflow: () => Promise<StartRenewalWorkflowResponse | undefined>;
  getRenewalInfo: ({
    orderUid,
    token,
  }: GetRenewalInfoProps) => Promise<RenewalInfoResponse | undefined>;
  renewalInfo: RenewalInfoResponse | undefined;
  openRenewalDialog: () => void;
}

export const Context = createContext<OrderRenewalController>({
  isLoading: false,
  startRenewalWorkflow: () => Promise.reject(),
  getRenewalInfo: () => Promise.reject(),
  renewalInfo: undefined,
  openRenewalDialog: () => undefined,
});

Context.displayName = "OrderRenewalContext";

const OrderRenewalProvider: FC = ({ children }) => {
  const [renewalInfo, setRenewalInfo] = useState<
    RenewalInfoResponse | undefined
  >();

  const [startRenewalWorkflowQuery] = useLazyQuery<{
    startRenewalWorkflow: StartRenewalWorkflowResponse;
  }>(OrderRenewalService.startRenewalWorkflow());

  const startRenewalWorkflow = async () => {
    const result = await startRenewalWorkflowQuery();
    return result.data?.startRenewalWorkflow;
  };

  const [renewalInfoQuery, { loading: isLoadingRenewalInfo }] = useLazyQuery<{
    getRenewalInfo: RenewalInfoResponse;
  }>(OrderRenewalService.getRenewalInfo(), {
    fetchPolicy: "no-cache",
  });

  const getRenewalInfo = async ({ orderUid, token }: GetRenewalInfoProps) => {
    const result = await renewalInfoQuery({
      variables: { orderUid, token },
    });
    if (result.data?.getRenewalInfo) {
      setRenewalInfo(result.data.getRenewalInfo);
    }
    return result.data?.getRenewalInfo;
  };

  const isLoading = useMemo(() => isLoadingRenewalInfo, [isLoadingRenewalInfo]);

  const renewalInfoDialog = useDialog(RenewalInfoDialog);
  const renewalPopUpDialog = useDialog(RenewalPopUp);

  const [renewalInfoDialogWasClosed, setRenewalInfoDialogWasClosed] = useState<
    boolean
  >(false);

  useEffect(() => {
    renewalInfoDialogWasClosed && openRenewalPopUp();
  }, [renewalInfoDialogWasClosed]);

  const openRenewalPopUp = () => {
    renewalPopUpDialog.open({
      onCloseDialog: renewalPopUpDialog.close,
      limsId: renewalInfo?.clinicLimsId,
      orderUid: renewalInfo?.renewalOrderUid,
    });
  };

  const closeRenewalDialog = () => {
    renewalInfoDialog.close();
    setRenewalInfoDialogWasClosed(true);
  };

  const openRenewalDialog = () => {
    setRenewalInfoDialogWasClosed(false);
    renewalInfoDialog.open({
      onCloseDialog: () => closeRenewalDialog(),
      limsId: renewalInfo?.clinicLimsId,
      orderUid: renewalInfo?.renewalOrderUid,
    });
  };

  const orderRenewalController: OrderRenewalController = useMemo(
    () => ({
      isLoading,
      startRenewalWorkflow,
      getRenewalInfo,
      renewalInfo,
      openRenewalDialog,
    }),
    [
      isLoading,
      startRenewalWorkflow,
      getRenewalInfo,
      renewalInfo,
      openRenewalDialog,
    ]
  );

  return (
    <Context.Provider value={orderRenewalController}>
      {renewalInfoDialog.getDialog()}
      {renewalPopUpDialog.getDialog()}
      {children}
    </Context.Provider>
  );
};

export default OrderRenewalProvider;
