import { TestCard, TestType } from "@app/provider/testData/types";

export const HOURS_24 = 24 * 60 * 60 * 1000; // 24 hour

export const getEstimatedResultsDeliveryDate = (
  testCard: TestCard,
  sampleReceivedDate: Date,
  isSignateraSubsequent?: boolean
): Date => {
  switch (testCard.testType) {
    case TestType.ALTERA:
      return new Date(sampleReceivedDate.getTime() + HOURS_24 * 14);
    case TestType.EMPOWER:
      return testCard.isEmpowerStatPanelIncluded &&
        !testCard.isLatestReleasedResultStat
        ? new Date(sampleReceivedDate.getTime() + HOURS_24 * 7)
        : new Date(sampleReceivedDate.getTime() + HOURS_24 * 21);
    case TestType.HORIZON:
      return new Date(sampleReceivedDate.getTime() + HOURS_24 * 21);
    case TestType.PANORAMA:
      return new Date(sampleReceivedDate.getTime() + HOURS_24 * 14);
    case TestType.PROSPERA:
      return new Date(sampleReceivedDate.getTime() + HOURS_24 * 4);
    case TestType.RENASIGHT:
      return new Date(sampleReceivedDate.getTime() + HOURS_24 * 28);
    case TestType.SIGNATERA:
      return isSignateraSubsequent
        ? new Date(sampleReceivedDate.getTime() + HOURS_24 * 10)
        : new Date(sampleReceivedDate.getTime() + HOURS_24 * 28);
    case TestType.VISTARA:
      return new Date(sampleReceivedDate.getTime() + HOURS_24 * 28);
    default:
      return sampleReceivedDate;
  }
};
