import React from "react";
import Svg from "@natera/material/lib/svg";
import { Document, Page, pdfjs } from "react-pdf";
import { Button } from "@natera/platform/lib/components/form";
import { defineMessages, useIntl } from "react-intl";

import { ServiceContext } from "@app/provider";
import { TestDetailsContext } from "@app/provider/testData";

import CloudError from "@assets/svg/icons/cloud-error.svg";
import "./pdf.scss";
import { HEAP_EVENTS } from "@app/provider/types";
import { ScrollContext } from "@natera/platform/lib/components/scrollbar";

// https://github.com/wojtekmaj/react-pdf/issues/97
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const messages = defineMessages({
  pdfDownload: {
    id: "pdfDownload",
    defaultMessage: "Download",
  },
  pdfLoadError: {
    id: "pdfLoadError",
    defaultMessage: "Sorry, we cannot retrieve results right now.",
  },
  pdfSubLoadError: {
    id: "pdfSubLoadError",
    defaultMessage: "Please try again later.",
  },
});

type onDocumentLoadSuccess = (pdf: pdfjs.PDFDocumentProxy) => Promise<void>;
type onPageLoadSuccess = (pdf: pdfjs.PDFPageProxy) => Promise<void>;

type Props = {
  file: string;
  fileName: string;
  downloadHeapEvent?: HEAP_EVENTS;
};

const StaticPdfViewer: React.FC<Props> = ({
  file,
  fileName,
  downloadHeapEvent,
}) => {
  const intl = useIntl();
  const { fileService } = React.useContext(ServiceContext);
  const { getTestDetails } = React.useContext(TestDetailsContext);
  const [numPages, setNumPages] = React.useState<number | null>(null);
  const [pdfWidth, setPdfWidth] = React.useState<number>();

  const refPdfContainer = React.useRef<HTMLDivElement>(null);

  const testDetails = getTestDetails();

  const onDownload = async () => {
    try {
      fileService.downloadStatic(file, fileName);
    } catch (error) {
      console.error(`Failed to download static file: ${file}, error: ${error}`);
    } finally {
      if (testDetails && downloadHeapEvent) {
        heap.track(downloadHeapEvent, {
          order_uid: testDetails.orderUid,
          lims_clinic_id: testDetails.clinic?.limsId,
        });
      }
    }
  };

  const onDocumentLoadSuccess: onDocumentLoadSuccess = async ({
    numPages: nextNumPages,
  }) => {
    setNumPages(nextNumPages);
  };

  const onPageLoadSuccess: onPageLoadSuccess = async () => {
    const containerWidth = refPdfContainer.current?.clientWidth;

    if (!containerWidth) {
      return;
    }

    setPdfWidth(containerWidth);
  };

  return (
    <section className="pdf__container" ref={refPdfContainer}>
      <div className="pdf__container__header">
        {file && (
          <div className="pdf__container__header__title">
            <Button
              className="pdf__container__header__download"
              raised
              onClick={onDownload}
            >
              {intl.formatMessage(messages.pdfDownload)}
            </Button>
          </div>
        )}
      </div>
      <ScrollContext
        component="div"
        isStatic={false}
        settings={{ suppressScrollX: true }}
      >
        {file && (
          <div>
            <div aria-hidden={true}>
              <Document
                className="pdf__container__header__result-pdf"
                file={file}
                onLoadSuccess={onDocumentLoadSuccess}
              >
                {Array.from(new Array(numPages), (el, index) => (
                  <Page
                    key={`page_${index + 1}`}
                    className="pdf__container__header__result-pdf-page"
                    pageNumber={index + 1}
                    renderTextLayer
                    renderAnnotationLayer
                    width={pdfWidth}
                    onLoadSuccess={onPageLoadSuccess}
                  />
                ))}
              </Document>
            </div>
          </div>
        )}
        {!file && (
          <div className="pdf__container__error">
            <div className="image">
              <Svg content={CloudError} />
            </div>
            <div className="main-text">
              <span>{intl.formatMessage(messages.pdfLoadError)}</span>
            </div>
            <div className="sub-text">
              <span>{intl.formatMessage(messages.pdfSubLoadError)}</span>
            </div>
          </div>
        )}
        <div className="pdf__container__footer">
          {file && (
            <Button
              className="pdf__container__footer__download"
              raised
              onClick={onDownload}
            >
              {intl.formatMessage(messages.pdfDownload)}
            </Button>
          )}
        </div>
      </ScrollContext>
    </section>
  );
};

export default StaticPdfViewer;
