import * as React from "react";
import { FC, useContext } from "react";
import Svg from "@natera/material/lib/svg";
import ReminderImage from "@assets/svg/icons/renewal-reminder.svg";
import { InfoCard } from "@natera/patient";
import { defineMessages, useIntl } from "react-intl";
import "./renewalBanner.scss";
import { HEAP_EVENTS } from "@app/provider/types";
import { UserContext } from "@app/provider";
import Button from "@natera/material/lib/button";

const messages = defineMessages({
  renewalBannerText: {
    id: "renewalBannerText",
    defaultMessage: "It's time to renew your Signatera test",
  },
  renewalBannerLinkButton: {
    id: "renewalBannerLinkButton",
    defaultMessage: "Learn more",
  },
});

export interface RenewalBannerProps {
  openRenewalDialog: () => void;
  isMobilePhlebotomyPage?: boolean;
  limsId?: number;
  orderUid?: string;
}

const RenewalBanner: FC<RenewalBannerProps> = ({
  openRenewalDialog,
  isMobilePhlebotomyPage,
  limsId,
  orderUid,
}) => {
  const intl = useIntl();
  const { uppUser } = useContext(UserContext);

  const openDialog = () => {
    openRenewalDialog();
    heap.track(
      isMobilePhlebotomyPage
        ? HEAP_EVENTS.upp_renewal_mp_signatera_click
        : HEAP_EVENTS.upp_renewal_homepage_signatera_click,
      {
        identity: uppUser?.patientUID,
        lims_clinic_id: limsId,
        order_uid: orderUid,
      }
    );
  };

  return (
    <InfoCard
      className={"block-info-card banner"}
      icon={""}
      contentAside={
        <Svg className="block-info-card__image bill" content={ReminderImage} />
      }
    >
      <div className="renewalBanner">
        <div data-testid="card-title" className="renewalBanner__title">
          <b>{intl.formatMessage(messages.renewalBannerText)}</b>
        </div>
        <Button
          className="renewalBanner__link"
          onClick={openDialog}
          aria-label={intl.formatMessage(messages.renewalBannerLinkButton)}
        >
          {intl.formatMessage(messages.renewalBannerLinkButton)}
        </Button>
      </div>
    </InfoCard>
  );
};

export default RenewalBanner;
