import * as React from "react";
import { useHistory } from "react-router-dom";
import { defineMessages, useIntl } from "react-intl";
import { SentEmailAfterUnlockView } from "@app/components";
import {
  ConfigContext,
  NotificationContext,
  ResetPasswordContext,
} from "@app/provider";
import { useQuery, validateEmail } from "@app/utils";
import { routes } from "@app/routing";
import "./sendEmail.scss";

const messages = defineMessages({
  sendEmailResetLinkSent: {
    id: "sendEmailResetLinkSent",
    defaultMessage:
      "We’ve sent you another reset link. Please follow the instructions",
  },
  sendEmailResendLinkTitle: {
    id: "sendEmailResendLinkTitle",
    defaultMessage: "Reset link sent",
  },
});

const SendEmail: React.FC = () => {
  const {
    resetPassword,
    resetPasswordData,
    resetPasswordIsLoading,
  } = React.useContext(ResetPasswordContext);
  const { addNotification, clear } = React.useContext(NotificationContext);
  const { config } = React.useContext(ConfigContext);
  const [resendIsDisabled, setResendIsDisabled] = React.useState(false);
  const resendTimeoutRef = React.useRef<NodeJS.Timeout>();
  const query = useQuery();
  const [email, setEmail] = React.useState(query.get("email") || "");
  const history = useHistory();
  const intl = useIntl();

  const usePlusSignInEmailAddress =
    config.test.usePlusSignInEmailAddress.enabled;

  const resendEmail = () => {
    clear();
    setResendIsDisabled(true);

    resetPassword(email).then((resp) => {
      if (resp?.email) {
        addNotification({
          title: intl.formatMessage(messages.sendEmailResendLinkTitle),
          message: intl.formatMessage(messages.sendEmailResetLinkSent),
          type: "info",
        });
      }
    });

    resendTimeoutRef.current = setTimeout(() => {
      setResendIsDisabled(false);

      if (resendTimeoutRef.current) {
        clearTimeout();
      }
    }, config.timeouts.resendTimeout);
  };

  React.useEffect(() => {
    if (resetPasswordData?.email) {
      setEmail(resetPasswordData.email);
    } else if (
      !query.get("email") ||
      !validateEmail(query.get("email") || "", usePlusSignInEmailAddress)
    ) {
      history.replace(routes.root);
    }
  }, []);

  return (
    <SentEmailAfterUnlockView
      email={email}
      handleResendEmail={resendEmail}
      resendIsDisabled={resendIsDisabled}
      isLoading={resetPasswordIsLoading}
      tryAnotherEmailLink={routes.resetPassword}
    />
  );
};

export default SendEmail;
