import React, { FC } from "react";
import { Svg } from "@natera/material/lib/svg";
import MaintenanceSvg from "@assets/svg/maintenance.svg";
import { ConfigContext } from "@app/provider";
import { getFormattedMaintenanceDateTime } from "@app/utils";

import Logo from "@assets/svg/natera-portal-logo.svg";

import "./maintenance.scss";
import { defineMessages, useIntl } from "react-intl";

const messages = defineMessages({
  maintenanceNateraHomeLogo: {
    id: "mainMenuNateraHomeLogo",
    defaultMessage: "Natera home",
  },
});

const Maintenance: FC = ({ children }) => {
  const intl = useIntl();

  const { config } = React.useContext(ConfigContext);

  const maintenance = config.maintenance;

  return maintenance?.enabled ? (
    <>
      <header className="maintenance-header header_limited">
        <div className="header__logo">
          <a
            href="/"
            aria-label={intl.formatMessage(messages.maintenanceNateraHomeLogo)}
          >
            <Svg content={Logo} role="img" />
          </a>
        </div>
      </header>
      <div className="maintenance">
        <Svg content={MaintenanceSvg} />
        <h3 className="title">
          Our site is down for maintenance.
          <br />
          Maintenance should be complete by
          <br />
          {getFormattedMaintenanceDateTime(maintenance.untilDateTime)}.<br />
          Thank you for your patience.
        </h3>
        <p className="maintenance-subtitle">
          Nuestro sitio está en mantenimiento.
          <br />
          El mantenimiento debería estar completo
          <br />
          para las {getFormattedMaintenanceDateTime(maintenance.untilDateTime)}.
          <br />
          Gracias por tu paciencia.
        </p>
      </div>
    </>
  ) : (
    <>{children}</>
  );
};

export default Maintenance;
