import React, { FC, useContext, useEffect, useState } from "react";
import { useIntl, defineMessages } from "react-intl";
import { useErrorController } from "@natera/platform/lib/hooks";
import {
  Progress,
  UpdateCommunicationForm,
  LinkHeap,
  FlowHeader,
  Notification,
  SetupProfileReviewInfo,
} from "@app/components";
import { ProfileContext } from "@app/provider/profile";
import { ResultCodes } from "@app/service/resultCodes";
import { SetupProfileForm } from "@app/components/forms";
import FactorList from "@app/components/factorList";
import {
  NotificationContext,
  OrderRenewalContext,
  UppAuthContext,
  UserContext,
} from "@app/provider";
import { STAGE } from "@app/service/user";
import SpinnerView from "@app/components/spinnerView";
import { Link, useHistory } from "react-router-dom";
import { routes } from "@app/routing";
import acknowledgements from "@etc/acknowledgements.json";
import { Communication, SetupProfileData } from "@app/provider/profile/types";
import { HEAP_EVENTS, HeapEventLocation } from "@app/provider/types";
import "./setupProfile.scss";

const messages = defineMessages({
  setupProfileStep: {
    id: "setupProfileStep",
    defaultMessage: "Step {number} of 2",
  },
  setupProfileTitle: {
    id: "setupProfileTitle",
    defaultMessage: "Tell us about yourself",
  },
  setupProfileTitleReviewInfo: {
    id: "setupProfileTitleReviewInfo",
    defaultMessage: "Review your information",
  },
  setupProfilePatientInformationBar: {
    id: "setupProfilePatientInformationBar",
    defaultMessage:
      "Information entered should match what is on file with your clinic, which normally matches what is on your insurance card.",
  },
  setupProfileTwoFactorTitle: {
    id: "setupProfileTwoFactorTitle",
    defaultMessage: "Two - Factor Authentication",
  },
  setupProfileUserAlreadyHaveLinkedProfileError: {
    id: "setupProfileUserAlreadyHaveLinkedProfileError",
    defaultMessage: "You already have linked profile",
  },
  setupProfileAlreadyLinked: {
    id: "setupProfileAlreadyLinked",
    defaultMessage:
      "Our records show that you already have an account with us. Please log in with the existing account.",
  },
  setupProfileLogin: {
    id: "setupProfileLogin",
    defaultMessage: "Log In",
  },
  setupProfileMobileValidationError: {
    id: "setupProfileMobileValidationError",
    defaultMessage: "You must enter a US based number to create an account.",
  },
  setupProfilesWeAreSorry: {
    id: "setupProfilesWeAreSorry",
    defaultMessage:
      "We're sorry. An error occurred when setting up your profile.",
  },
  setupProfilesContactSupport: {
    id: "setupProfilesContactSupport",
    defaultMessage: "Contact support",
  },
  setupProfileCommunicationPreferencesTitle: {
    id: "setupProfileCommunicationPreferencesTitle",
    defaultMessage: "Communication Preferences",
  },
});

const SetupProfile: FC = () => {
  const intl = useIntl();
  const history = useHistory();

  const {
    isLoading: isProfileLoading,
    uppUser,
    loadProfile,
    updateUser,
  } = useContext(UserContext);
  const { profile: uppProfile } = React.useContext(UppAuthContext);
  const { startRenewalWorkflow } = React.useContext(OrderRenewalContext);

  const { addNotification, clear } = useContext(NotificationContext);
  const [step, setStep] = useState(0);
  const [filledProfileData, setFilledProfileData] = useState<
    SetupProfileData
  >();
  const [isReviewInfoScreen, setIsReviewInfoScreen] = useState(false);
  const errorController = useErrorController();

  const {
    setupProfileIsLoading: isLoading,
    setupProfile,
    setupProfileError,
    setupProfileData,
    profileData,
    skipCommunicationPreferencesStep,
    updatePatientCommunication,
  } = useContext(ProfileContext);

  useEffect(() => {
    loadProfile();
  }, []);

  useEffect(() => {
    if (uppProfile?.patientUID) {
      startRenewalWorkflow();
    }
  }, [uppProfile]);

  useEffect(() => {
    if (setupProfileError === ResultCodes.PROFILE_ALREADY_LINKED_ERROR) {
      addNotification({
        type: "error",
        message: intl.formatMessage(messages.setupProfileAlreadyLinked),
        actions: (
          <Link to={`${routes.logOut}?code=${ResultCodes.SIGN_OUT}`}>
            {intl.formatMessage(messages.setupProfileLogin)}
          </Link>
        ),
      });
    } else if (
      setupProfileError === ResultCodes.USER_ALREADY_HAVE_LINKED_PROFILE
    ) {
      addNotification({
        type: "error",
        message: intl.formatMessage(
          messages.setupProfileUserAlreadyHaveLinkedProfileError
        ),
      });
    } else if (setupProfileError === ResultCodes.PHONE_VALIDATION_FAILED) {
      errorController.setValidationError(
        "mobileNumber",
        intl.formatMessage(messages.setupProfileMobileValidationError)
      );
    } else if (
      setupProfileError === ResultCodes.PROFILE_SETUP_ERROR ||
      setupProfileError
    ) {
      addNotification({
        type: "error",
        message: intl.formatMessage(messages.setupProfilesWeAreSorry),
        actions: (
          <LinkHeap
            target="_blank"
            key="privacyPolicy"
            rel="noreferrer"
            to={{ pathname: acknowledgements.links.contactUs }}
            heapEventName={HEAP_EVENTS.upp_click_contactnatera}
            heapEventProps={{
              location: HeapEventLocation.update_info_error,
            }}
          >
            {intl.formatMessage(messages.setupProfilesContactSupport)}
          </LinkHeap>
        ),
      });
    }
  }, [setupProfileError]);

  useEffect(() => {
    if (
      uppUser?.stage === STAGE.COMMUNICATION_PREFERENCES &&
      skipCommunicationPreferencesStep
    ) {
      updateUser({ stage: STAGE.FILLED });
    }
  }, [skipCommunicationPreferencesStep, uppUser?.stage]);

  useEffect(() => {
    if (uppUser) {
      switch (uppUser.stage) {
        case STAGE.BLANK:
          updateUser({ stage: STAGE.PROFILE_SETUP });
          setStep(1);
          break;
        case STAGE.PROFILE_SETUP:
          setStep(1);
          break;
        case STAGE.MFA_SETUP:
          setStep(2);
          break;
        case STAGE.COMMUNICATION_PREFERENCES:
          setStep(3);
          break;
        default:
          history.replace(routes.home + history.location.search);
      }
    }
  }, [uppUser]);

  const handleProfileFormSubmit = async (profileData: SetupProfileData) => {
    setFilledProfileData(profileData);
    setIsReviewInfoScreen(true);
  };

  const handleReviewInfoEdit = () => {
    setIsReviewInfoScreen(false);
    heap.track(HEAP_EVENTS.upp_patientinfo_edit);
  };

  const handleReviewInfoSubmit = async () => {
    clear();
    await setupProfile({ ...filledProfileData, email: uppUser?.email });
    loadProfile();
  };

  const handleCommunicationFormSubmit = async (
    communicationData: Communication[]
  ) => {
    await updatePatientCommunication(
      communicationData,
      HeapEventLocation.accountCreation
    );
    loadProfile();
  };

  const getTitle = () => {
    switch (step) {
      case 1: {
        return isReviewInfoScreen
          ? intl.formatMessage(messages.setupProfileTitleReviewInfo)
          : intl.formatMessage(messages.setupProfileTitle);
      }
      case 2: {
        return intl.formatMessage(messages.setupProfileTwoFactorTitle);
      }
      case 3: {
        return intl.formatMessage(
          messages.setupProfileCommunicationPreferencesTitle
        );
      }
    }
  };

  return (
    <article className="setup-profile__container">
      <SpinnerView isLoading={isProfileLoading} />
      <FlowHeader />
      {step !== 3 && (
        <Progress className="setup-profile__progress" value={step * 50} />
      )}
      <div className="setup-profile__wrapper">
        <section className="setup-profile__content">
          {step === 1 && isReviewInfoScreen && (
            <Notification key={"patientInformationBarId"} type="warning">
              {intl.formatMessage(messages.setupProfilePatientInformationBar)}
            </Notification>
          )}
          {step !== 3 && step !== 0 && (
            <h2 className="step-number">
              {intl.formatMessage(messages.setupProfileStep, {
                number: step,
              })}
            </h2>
          )}
          <h3 className="setup-profile__title">{getTitle()}</h3>
          {step === 1 &&
            (isReviewInfoScreen && filledProfileData ? (
              <SetupProfileReviewInfo
                profileData={filledProfileData}
                onEdit={handleReviewInfoEdit}
                onSubmit={handleReviewInfoSubmit}
                isLoading={isLoading}
              />
            ) : (
              <SetupProfileForm
                initialProfileData={filledProfileData}
                onSubmit={handleProfileFormSubmit}
                clearErrors={errorController.clearErrors}
                setValidationError={errorController.setValidationError}
                getValidationError={errorController.getValidationError}
                hasValidationError={errorController.hasError}
              />
            ))}
          {step === 2 && uppUser?.askForMfa && (
            <FactorList
              isFirstSetup
              profile={{ ...setupProfileData, email: uppUser.email }}
            />
          )}
          {step === 3 && !skipCommunicationPreferencesStep && (
            <UpdateCommunicationForm
              communicationData={profileData?.communicationPreferences}
              handleSubmit={handleCommunicationFormSubmit}
              className="communication-preferences"
            />
          )}
        </section>
      </div>
    </article>
  );
};

export default SetupProfile;
