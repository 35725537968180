import { defineMessages, IntlShape } from "react-intl";
import { PhoneValidation } from "@natera/platform/lib/hooks";

const messages = defineMessages({
  updatePhoneFillNumber: {
    id: "updatePhoneFillNumber",
    defaultMessage: "Please fill in this field.",
  },
  updatePhoneInvalidPhoneNumber: {
    id: "updatePhoneInvalidPhoneNumber",
    defaultMessage: "Please enter a valid phone number.",
  },
  updatePhoneUSPhoneNumber: {
    id: "updatePhoneUSPhoneNumber",
    defaultMessage: "You must enter a US based number to create an account.",
  },
});

export interface GetPhoneValidationErrorMessageProps {
  isMandatory: boolean;
  mobileNumber: string;
  intl: IntlShape;
  phoneValidation: PhoneValidation;
  validateFromPS: (
    mobileNumber: string
  ) => Promise<
    | {
        isValid: boolean;
      }
    | undefined
  >;
}
type GetPhoneValidationErrorMessage = (
  props: GetPhoneValidationErrorMessageProps
) => Promise<string | undefined>;

export const getPhoneValidationErrorMessage: GetPhoneValidationErrorMessage = async ({
  isMandatory,
  mobileNumber,
  intl,
  phoneValidation,
  validateFromPS,
}) => {
  const { validatePhone } = phoneValidation;
  if (!mobileNumber && !isMandatory) {
    return;
  }

  if (!mobileNumber && isMandatory) {
    return intl.formatMessage(messages.updatePhoneFillNumber);
  }

  if (mobileNumber.length < 11) {
    return intl.formatMessage(messages.updatePhoneInvalidPhoneNumber);
  }

  if (!mobileNumber.startsWith("1")) {
    return intl.formatMessage(messages.updatePhoneUSPhoneNumber);
  }

  if (!validatePhone(mobileNumber, "US")) {
    return intl.formatMessage(messages.updatePhoneInvalidPhoneNumber);
  }

  const checkPhone = await validateFromPS(mobileNumber);

  if (!checkPhone?.isValid) {
    return intl.formatMessage(messages.updatePhoneInvalidPhoneNumber);
  }

  return;
};
