import R from "ramda";
import React, {
  createContext,
  FC,
  useContext,
  useEffect,
  useState,
} from "react";
import { useLazyQuery } from "@apollo/client";
import TestCardService from "@app/service/testCard";
import { NotificationContext, TestCardUtilsContext } from "@app/provider";
import {
  ParsedTestCard,
  SecondaryCard,
  TestCard,
} from "@app/provider/testData/types";
import { useIntl } from "react-intl";
import { AddNotificationParameters } from "@app/provider/notification";
import { getExtendedServerErrorNotificationParams } from "@app/utils/getExtendedServerErrorNotificationParams";

export interface TestCardController {
  isLoading: boolean;
  testCards: ParsedTestCard[] | undefined;
  secondaryActionCards: SecondaryCard[];
  getTestCards: () => Promise<ParsedTestCard[]>;
  getPrimaryTestCards: () => Promise<ParsedTestCard[]>;
  primaryTestCardsLoading: boolean;
  isTestsHistoryExists: boolean | undefined;
}

export const Context = createContext<TestCardController>({
  isLoading: false,
  testCards: undefined,
  secondaryActionCards: [],
  getTestCards: async () => [],
  getPrimaryTestCards: async () => [],
  primaryTestCardsLoading: false,
  isTestsHistoryExists: undefined,
});

Context.displayName = "TestCardContext";

const TestCardProvider: FC = ({ children }) => {
  const intl = useIntl();

  const rerenderCardData = () => {
    const result = R.map(
      (testCard) => getTestCardData(testCard),
      testCardsData
    );
    setParsedTestCards(result);

    setSecondaryActionCards(
      R.map(
        (secondaryCard) => getSecondaryActionCardData(secondaryCard),
        secondaryActionCards
      )
    );
  };

  const { getTestCardData, getSecondaryActionCardData } = useContext(
    TestCardUtilsContext
  );

  const [testCardsData, setTestCardsData] = useState<TestCard[]>([]);
  const [parsedTestCards, setParsedTestCards] = useState<
    ParsedTestCard[] | undefined
  >(undefined);
  const [secondaryActionCards, setSecondaryActionCards] = useState<
    SecondaryCard[]
  >([]);
  const [isTestsHistoryExists, setIsTestsHistoryExists] = useState<
    boolean | undefined
  >(undefined);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { addNotification } = useContext(NotificationContext);
  const [
    getPrimaryTestCardsQuery,
    { loading: primaryTestCardsLoading },
  ] = useLazyQuery(TestCardService.getTestCards(), {
    fetchPolicy: "no-cache",
  });

  const [getSecondaryCardsQuery] = useLazyQuery(
    TestCardService.getSecondaryCards(),
    {
      fetchPolicy: "no-cache",
    }
  );

  const [isUserHasArchivedTestsQuery] = useLazyQuery(
    TestCardService.isUserHasArchivedTests()
  );

  useEffect(() => {
    rerenderCardData();
  }, [intl.locale, testCardsData.length, secondaryActionCards.length]);

  const getPrimaryTestCards = async () => {
    const { data, error } = await getPrimaryTestCardsQuery();

    if (error) {
      const notificationParams: AddNotificationParameters = getExtendedServerErrorNotificationParams(
        error
      );
      addNotification(notificationParams);
      return [];
    }

    const testCards: TestCard[] = data?.getTestCards;

    return R.map((testCard) => getTestCardData(testCard), testCards);
  };

  const getTestCards = async () => {
    let result: ParsedTestCard[] = [];

    setIsLoading(true);
    const { data, error } = await getPrimaryTestCardsQuery();

    const {
      data: secondaryData,
      error: secondaryError,
    } = await getSecondaryCardsQuery();

    const {
      data: isUserHasArchivedTestsData,
      error: isUserHasArchivedTestsError,
    } = await isUserHasArchivedTestsQuery();

    setIsLoading(false);

    if (error || secondaryError || isUserHasArchivedTestsError) {
      const notificationParams: AddNotificationParameters = getExtendedServerErrorNotificationParams(
        error
      );
      addNotification(notificationParams);
      return result;
    }

    const testCards: TestCard[] = data?.getTestCards;
    const secondaryCards: SecondaryCard[] = secondaryData?.getSecondaryCards;
    const isUserHasArchivedTests: boolean =
      isUserHasArchivedTestsData?.isUserHasArchivedTests;

    if (testCards) {
      result = R.map((testCard) => getTestCardData(testCard), testCards);
      setTestCardsData(testCards);
      setParsedTestCards(result);
    }

    if (secondaryCards) {
      setSecondaryActionCards(
        R.map(
          (secondaryCard) => getSecondaryActionCardData(secondaryCard),
          secondaryCards
        )
      );
    }

    if (isUserHasArchivedTests) {
      setIsTestsHistoryExists(isUserHasArchivedTests);
    }

    return result;
  };

  return (
    <Context.Provider
      value={{
        isLoading,
        testCards: parsedTestCards,
        getTestCards,
        getPrimaryTestCards,
        primaryTestCardsLoading,
        secondaryActionCards,
        isTestsHistoryExists,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export default TestCardProvider;
