import React, { FC } from "react";
import { Dialog } from "@natera/material/lib/dialog";
import { defineMessages, useIntl } from "react-intl";
import { Button } from "@natera/platform/lib/components/form";
import { useHistory } from "react-router-dom";
import { routes } from "@app/routing";
import { ConfigContext } from "@app/provider";
import { HEAP_EVENTS } from "@app/provider/types";
import { LinkHeap } from "@app/components";

import "./apologiesModal.scss";

const messages = defineMessages({
  apologiesModalMessageTitle: {
    id: "apologiesModalMessageTitle",
    defaultMessage: "We're sorry.",
  },
  apologiesModalMessageModify: {
    id: "apologiesModalMessageModify",
    defaultMessage:
      "We are unable to modify your appointment request at this time.",
  },
  apologiesModalMessageCreate: {
    id: "apologiesModalMessageCreate",
    defaultMessage:
      "We are unable to submit your appointment request at this time.",
  },
  apologiesModalMessageTextContact: {
    id: "apologiesModalMessageTextContact",
    defaultMessage: "Any questions? {contactUs}",
  },
  apologiesModalMessageTextContactUs: {
    id: "apologiesModalMessageTextContactUs",
    defaultMessage: "Contact us",
  },
  apologiesModalMessageBackToHome: {
    id: "apologiesModalMessageBackToHome",
    defaultMessage: "Home",
  },
  apologiesModalMessageBack: {
    id: "apologiesModalMessageBack",
    defaultMessage: "Back",
  },
});

interface ApologiesModalLocationState {
  prevPage: string;
}

type Props = {
  isModify: boolean;
  onClose: () => void;
  isGuest?: boolean;
};

const ApologiesModal: FC<Props> = ({ isModify, onClose, isGuest }) => {
  const intl = useIntl();
  const { config } = React.useContext(ConfigContext);

  const history = useHistory();
  const state = history.location.state as
    | ApologiesModalLocationState
    | undefined;
  const isBackToHome = !isModify || (isModify && !state?.prevPage);

  const handleClick = () => {
    if (isGuest) {
      onClose();
    } else {
      history.length > 1 ? history.goBack() : history.push(routes.home);
    }
  };

  return (
    <Dialog
      className="mobile-phlebotomy-modal"
      onClose={handleClick}
      title={intl.formatMessage(messages.apologiesModalMessageTitle)}
      showCrossButton
      actions={
        <Button raised onClick={handleClick}>
          {isGuest
            ? intl.formatMessage(messages.apologiesModalMessageBack)
            : isBackToHome
            ? intl.formatMessage(messages.apologiesModalMessageBackToHome)
            : intl.formatMessage(messages.apologiesModalMessageBack)}
        </Button>
      }
    >
      <div className="mobile-phlebotomy-modal__container">
        <p>
          {isModify
            ? intl.formatMessage(messages.apologiesModalMessageModify)
            : intl.formatMessage(messages.apologiesModalMessageCreate)}
        </p>
        <p>
          {intl.formatMessage(messages.apologiesModalMessageTextContact, {
            contactUs: (
              <LinkHeap
                target="_blank"
                key="privacyPolicy"
                rel="noopener noreferrer"
                to={{ pathname: config.links.CONTACT_US }}
                heapEventName={HEAP_EVENTS.upp_click_contactnatera}
              >
                {intl.formatMessage(
                  messages.apologiesModalMessageTextContactUs
                )}
              </LinkHeap>
            ),
          })}
        </p>
      </div>
    </Dialog>
  );
};

export default ApologiesModal;
