import { DocumentNode, gql } from "@apollo/client";

class KitService {
  public static addKit = (): DocumentNode => gql`
    mutation placeKitOrder(
      $orderUid: String!
      $testUid: String!
      $address: AddressOptional!
      $token: String
    ) {
      placeKitOrder(
        orderUid: $orderUid
        testUid: $testUid
        address: $address
        token: $token
      ) {
        result
        kitOrderSkippedForPR
      }
    }
  `;
}

export default KitService;
