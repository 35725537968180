import React, { FC, createContext, useMemo, useState } from "react";
import { useMutation } from "@apollo/client";
import KitService from "@app/service/kit";
import { Address } from "./profile";

export interface KitController {
  addKit: (
    orderUid: string,
    testUid: string,
    address: Partial<Address>,
    token?: string
  ) => Promise<{ result: boolean; kitOrderSkippedForPR?: boolean }>;
  addKitIsLoading: boolean;
  skipKitShipping: boolean;
  setSkipKitShipping: (value: boolean) => void;
  skipLabStep: boolean;
  setSkipLabStep: (value: boolean) => void;
}
export const Context = createContext<KitController>({
  addKit: () => Promise.reject(),
  addKitIsLoading: false,
  skipKitShipping: false,
  setSkipKitShipping: () => {
    console.warn("setSkipKitShipping is not implemented");
  },
  skipLabStep: false,
  setSkipLabStep: () => {
    console.warn("setSkipLabStep is not implemented");
  },
});

Context.displayName = "KitContext";

const KitProvider: FC = ({ children }) => {
  const [addKit, { loading }] = useMutation<{
    placeKitOrder: {
      result: boolean;
      kitOrderSkippedForPR?: boolean;
    };
  }>(KitService.addKit(), {
    fetchPolicy: "no-cache",
  });

  const handleAddKit = async (
    orderUid: string,
    testUid: string,
    address: Partial<Address>,
    token?: string
  ) => {
    try {
      const result = await addKit({
        variables: {
          orderUid,
          testUid,
          address,
          token,
        },
      });

      return {
        result: Boolean(result.data?.placeKitOrder?.result),
        kitOrderSkippedForPR: Boolean(
          result.data?.placeKitOrder?.kitOrderSkippedForPR
        ),
      };
    } catch (error) {
      return { result: false };
    }
  };

  const [skipKitShipping, setSkipKitShipping] = useState(false);
  const handleSetSkipKitShipping = (value: boolean) => {
    setSkipKitShipping(value);
  };

  const [skipLabStep, setSkipLabStep] = useState(false);
  const handleSetSkipLabStep = (value: boolean) => {
    setSkipLabStep(value);
  };

  const contextValue = useMemo(
    () => ({
      addKit: handleAddKit,
      addKitIsLoading: loading,
      skipKitShipping,
      setSkipKitShipping: handleSetSkipKitShipping,
      skipLabStep,
      setSkipLabStep: handleSetSkipLabStep,
    }),
    [handleAddKit, loading, skipKitShipping, handleSetSkipKitShipping]
  );

  return <Context.Provider value={contextValue}>{children}</Context.Provider>;
};

export default KitProvider;
