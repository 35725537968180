import React, { FC, useContext, useState } from "react";
import { defineMessages, useIntl } from "react-intl";
import { NotificationContext, UserContext } from "@app/provider";
import { routes } from "@app/routing";
import { SentEmailAfterUnlockView } from "@app/components";
import "./unlock.scss";

export interface CredentialsInterface {
  email: string;
  dateOfBirth?: string;
  lastName?: string;
}

const messages = defineMessages({
  unlockResentMessage: {
    id: "unlockResentText",
    defaultMessage:
      "We’ve sent you another reset link. Please follow the instructions",
  },
  unlockResentHeader: {
    id: "unlockResentHeader",
    defaultMessage: "Reset link sent",
  },
});

interface MailSentViewProps {
  email: string;
  credentials?: CredentialsInterface;
}

export const MailSentView: FC<MailSentViewProps> = ({ email, credentials }) => {
  const intl = useIntl();

  const { clear, addNotification } = useContext(NotificationContext);
  const { unlockUser, isLoading, clearUnlockUserData } = useContext(
    UserContext
  );

  const [resendButtonIsDisabled, setResendButtonIsDisabled] = useState(false);

  const disableResendButton = () => {
    const millisecondsInOneMinute = 60 * 1000;

    setResendButtonIsDisabled(true);
    setTimeout(() => setResendButtonIsDisabled(false), millisecondsInOneMinute);
  };

  const resendUnlockMail = () => {
    clear();
    credentials &&
      unlockUser(
        credentials.email,
        credentials.dateOfBirth,
        credentials.lastName
      ).then((response) => {
        if (response?.data?.success) {
          addNotification({
            title: intl.formatMessage(messages.unlockResentHeader),
            message: intl.formatMessage(messages.unlockResentMessage),
            type: "info",
          });
        }
      });
    disableResendButton();
  };

  return (
    <SentEmailAfterUnlockView
      email={email}
      handleResendEmail={resendUnlockMail}
      resendIsDisabled={isLoading || resendButtonIsDisabled}
      isLoading={isLoading}
      tryAnotherEmailLink={routes.unlock}
      handleTryAnotherEmail={clearUnlockUserData}
    />
  );
};

export default MailSentView;
