import React, { FC, useContext, useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useIntl, defineMessages } from "react-intl";
import { ResultCodes } from "@app/service/resultCodes";
import { useErrorController } from "@natera/platform/lib/hooks";
import {
  Progress,
  UpdateCommunicationForm,
  LinkHeap,
  FlowHeader,
} from "@app/components";
import { Profile, ProfileContext } from "@app/provider/profile";
import FactorList from "@app/components/factorList";
import {
  NotificationContext,
  OrderRenewalContext,
  UppAuthContext,
  UserContext,
} from "@app/provider";
import { routes } from "@app/routing";
import { STAGE } from "@app/service/user";
import ConfirmationForm from "./confirmationForm";
import { Communication } from "@app/provider/profile/types";
import { HEAP_EVENTS, HeapEventLocation } from "@app/provider/types";
import acknowledgements from "@etc/acknowledgements.json";
import "./confirmation.scss";

const messages = defineMessages({
  confirmationStep: {
    id: "step",
    defaultMessage: "Step {number} of {count}",
  },
  confirmationTitle: {
    id: "confirmationTitle",
    defaultMessage: "Confirm Your Information",
  },
  confirmationTwoFactorTitle: {
    id: "confirmationTwoFactorTitle",
    defaultMessage: "Two - Factor Authentication",
  },
  confirmationUserAlreadyHaveLinkedProfileError: {
    id: "confirmationUserAlreadyHaveLinkedProfileError",
    defaultMessage:
      "Our records show that you already have an account with us. Please log in with the existing account.",
  },
  confirmationProfileAlreadyLinked: {
    id: "confirmationProfileAlreadyLinked",
    defaultMessage: "Profile already linked to another user",
  },
  confirmationMobileValidationError: {
    id: "confirmationMobileValidationError",
    defaultMessage: "You must enter a US based number to create an account.",
  },
  confirmationCommunicationPreferencesTitle: {
    id: "confirmationCommunicationPreferencesTitle",
    defaultMessage: "Communication Preferences",
  },
  confirmationLogin: {
    id: "confirmationLogin",
    defaultMessage: "Log In",
  },
  confirmationWeAreSorry: {
    id: "confirmationWeAreSorry",
    defaultMessage:
      "We're sorry. An error occurred when setting up your profile.",
  },
  confirmationContactSupport: {
    id: "confirmationContactSupport",
    defaultMessage: "Contact support",
  },
});

const Confirmation: FC = () => {
  const intl = useIntl();
  const history = useHistory();

  const {
    uppUser,
    loadProfile,
    updateUser,
    isLoading: isProfileLoading,
  } = useContext(UserContext);
  const { profile: uppProfile } = React.useContext(UppAuthContext);
  const { startRenewalWorkflow } = React.useContext(OrderRenewalContext);

  const [step, setStep] = useState(2);
  const errorController = useErrorController();

  const {
    confirmProfileIsLoading,
    confirmProfile,
    confirmProfileError,
    confirmProfileData,
    getProfileIsLoading,
    verifyProfileData,
    profileData,
    skipCommunicationPreferencesStep,
    updatePatientCommunication,
  } = useContext(ProfileContext);
  const { addNotification } = useContext(NotificationContext);

  const [profile, setProfile] = useState<Profile | undefined>(undefined);
  const [phone, setPhone] = useState<string | undefined>(undefined);

  useEffect(() => {
    loadProfile();
  }, []);

  useEffect(() => {
    if (uppProfile?.patientUID) {
      startRenewalWorkflow();
    }
  }, [uppProfile]);

  useEffect(() => {
    if (
      !isProfileLoading &&
      uppUser?.stage === STAGE.COMMUNICATION_PREFERENCES &&
      skipCommunicationPreferencesStep
    ) {
      updateUser({ stage: STAGE.FILLED });
    }
  }, [skipCommunicationPreferencesStep, isProfileLoading]);

  useEffect(() => {
    if (uppUser?.stage === STAGE.MFA_SETUP) {
      setStep(3);
    } else if (uppUser?.stage === STAGE.COMMUNICATION_PREFERENCES) {
      setStep(4);
    } else if (uppUser?.stage === STAGE.FILLED) {
      history.replace(routes.home + history.location.search);
    } else if (uppUser && !verifyProfileData) {
      history.replace(routes.dateOfBirthVerification);
    }
  }, [uppUser]);

  useEffect(() => {
    if (verifyProfileData?.profile) {
      setProfile(verifyProfileData?.profile);
    }
  }, [verifyProfileData]);

  useEffect(() => {
    if (confirmProfileData) {
      setStep(3);
    }
  }, [confirmProfileData]);

  useEffect(() => {
    if (confirmProfileError === ResultCodes.PHONE_VALIDATION_FAILED) {
      errorController.setValidationError(
        "mobileNumber",
        intl.formatMessage(messages.confirmationMobileValidationError)
      );
    }
    if (confirmProfileError === ResultCodes.PROFILE_ALREADY_LINKED_ERROR) {
      addNotification({
        type: "error",
        message: intl.formatMessage(
          messages.confirmationUserAlreadyHaveLinkedProfileError
        ),
        actions: (
          <Link to={`${routes.logOut}?code=${ResultCodes.SIGN_OUT}`}>
            {intl.formatMessage(messages.confirmationLogin)}
          </Link>
        ),
      });
    }
    if (confirmProfileError === ResultCodes.PROFILE_SETUP_ERROR) {
      addNotification({
        type: "error",
        message: intl.formatMessage(messages.confirmationWeAreSorry),
        actions: (
          <LinkHeap
            target="_blank"
            key="privacyPolicy"
            rel="noreferrer"
            to={{ pathname: acknowledgements.links.contactUs }}
            heapEventName={HEAP_EVENTS.upp_click_contactnatera}
            heapEventProps={{
              location: HeapEventLocation.account_activated_error,
            }}
          >
            {intl.formatMessage(messages.confirmationContactSupport)}
          </LinkHeap>
        ),
      });
    }
  }, [confirmProfileError]);

  const handleConfirmationFormSubmit = async (profileData: Profile) => {
    await confirmProfile(profileData);
    loadProfile();
  };

  const handleCommunicationFormSubmit = async (
    communicationData: Communication[]
  ) => {
    await updatePatientCommunication(
      communicationData,
      HeapEventLocation.accountCreation
    );
    loadProfile();
  };

  const getTitle = () => {
    switch (step) {
      case 1:
      case 2: {
        return intl.formatMessage(messages.confirmationTitle);
      }
      case 3: {
        return intl.formatMessage(messages.confirmationTwoFactorTitle);
      }
      case 4: {
        return intl.formatMessage(
          messages.confirmationCommunicationPreferencesTitle
        );
      }
    }
  };

  return (
    <article className="setup-profile__container">
      <FlowHeader />
      {step !== 4 && (
        <Progress className="setup-profile__progress" value={step * 33} />
      )}
      <div className="setup-profile__wrapper">
        <section className="setup-profile__content">
          {step !== 4 && (
            <h2 className="step-number">
              {intl.formatMessage(messages.confirmationStep, {
                number: step,
                count: 3,
              })}
            </h2>
          )}
          <h3 className="setup-profile__title">{getTitle()}</h3>
          {step === 2 && profile && (
            <ConfirmationForm
              isLoading={confirmProfileIsLoading || getProfileIsLoading}
              profile={profile}
              onSubmit={handleConfirmationFormSubmit}
              clearErrors={errorController.clearErrors}
              clearValidationError={errorController.clearValidationError}
              setValidationError={errorController.setValidationError}
              getValidationError={errorController.getValidationError}
              setPhone={setPhone}
            />
          )}
          {step === 3 && uppUser?.askForMfa && (
            <FactorList
              isConfirmation
              isFirstSetup
              profile={{ ...confirmProfileData, email: uppUser.email }}
              phone={phone}
            />
          )}
          {step === 4 && !skipCommunicationPreferencesStep && (
            <UpdateCommunicationForm
              communicationData={profileData?.communicationPreferences}
              handleSubmit={handleCommunicationFormSubmit}
              className="communication-preferences"
            />
          )}
        </section>
      </div>
    </article>
  );
};

export default Confirmation;
