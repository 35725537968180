import React, { FC, useContext, useState } from "react";
import { defineMessages, useIntl } from "react-intl";
import "./renewalInfoDialog.scss";
import useIsMobileDimension from "../../hooks/useIsMobileDimension";
import RecurringTestingImage from "@assets/images/recurringTestingSGN.png";
import { VideoPlayer } from "@app/components";
import { HEAP_EVENTS } from "@app/provider/types";
import { ConfigContext, NotificationContext, UserContext } from "@app/provider";
import Dialog from "@natera/material/lib/dialog";
import R from "ramda";

const messages = defineMessages({
  renewalPageTitle: {
    id: "renewalPageTitle",
    defaultMessage: "Renew Your Test",
  },
  renewalPageHeader: {
    id: "renewalPageHeader",
    defaultMessage: "Knowing earlier matters",
  },
  renewalPageHeaderInfo: {
    id: "renewalPageHeaderInfo",
    defaultMessage:
      "You have been receiving recurring Signatera blood tests and your order is up for renewal. Your doctor will recommend when and how often you will have your blood drawn for a Signatera test, but it may be time to discuss renewing your recurring order.",
  },
  renewalPageEnsuring: {
    id: "renewalPageEnsuring",
    defaultMessage: "How do I ensure I am re-enrolled?",
  },
  renewalPageEnsuringInfo: {
    id: "renewalPageEnsuringInfo",
    defaultMessage:
      "To continue receiving Signatera tests on an ongoing basis, {boldContent}. Once your doctor has renewed your recurring Signatera order, Natera is available to work with you to schedule mobile phlebotomy services and assist with scheduling your blood draws at your home or work. Your Natera team is standing by to assist with everything from scheduling blood draws and genetic counselor information sessions to financial assistance.",
  },
  renewalPageEnsuringInfoBoldContent: {
    id: "renewalPageEnsuringInfoBoldContent",
    defaultMessage: "talk with your doctor about renewing your enrollment",
  },
  renewalPageQuestions: {
    id: "renewalPageQuestions",
    defaultMessage: "Any questions?",
  },
  renewalPageAssistance: {
    id: "renewalPageAssistance",
    defaultMessage:
      "For more assistance with renewing your recurring orders, contact us at 650.446.4657 or at {link} to speak with our Patient Coordinator team.",
  },
  renewalPageAssistanceLink: {
    id: "renewalPageAssistanceLink",
    defaultMessage: "oncologypc@natera.com",
  },
  renewalPageRecurringTesting: {
    id: "renewalPageRecurringTesting",
    defaultMessage: "Why is recurring testing important?",
  },
  renewalPageRecurringTestingImage: {
    id: "renewalPageRecurringTestingImage",
    defaultMessage: "Recurring testing image",
  },
  renewalPageRecurringTestingInfo: {
    id: "renewalPageRecurringTestingInfo",
    defaultMessage:
      "Using Signatera, we can measure changes in the amount of circulating tumor DNA (ctDNA) in your blood over time. You and your doctor can use this information to understand if there may be cancer remaining after treatment, how your cancer is responding to therapy, or if your cancer may be recurring. Receiving this information throughout your treatment journey ensures you and your doctor have real time guidance about how to optimize your treatment plan.",
  },
  renewalPageTestimonials: {
    id: "renewalPageTestimonials",
    defaultMessage: "Testimonials",
  },
  renewalPageTestimonialsFirstVideoTitle: {
    id: "renewalPageTestimonialsFirstVideoTitle",
    defaultMessage: "Keith’s Story",
  },
  renewalPageTestimonialsFirstVideoText: {
    id: "renewalPageTestimonialsFirstVideoText",
    defaultMessage:
      "Listen in how one CRC patient and his physician used Signatera as a part of his treatment strategy to help identify an early signal that cancer may be lurking.",
  },
  renewalPageTestimonialsSecondVideoTitle: {
    id: "renewalPageTestimonialsSecondVideoTitle",
    defaultMessage: "Angela’s Story",
  },
  renewalPageTestimonialsSecondVideoText: {
    id: "renewalPageTestimonialsSecondVideoText",
    defaultMessage:
      "Monitoring ctDNA in Breast Cancer: Moving on with life with Signatera.",
  },
  renewalPageResourses: {
    id: "renewalPageResourses",
    defaultMessage: "Resourses",
  },
  renewalPageResoursesCancerSociety: {
    id: "renewalPageResoursesCancerSociety",
    defaultMessage:
      "For more information about your diagnosis, check out the {americanCancerSociety} at Cancer.org",
  },
  renewalPageAmericanCancerSociety: {
    id: "renewalPageAmericanCancerSociety",
    defaultMessage: "American Cancer Society",
  },
  renewalPageResoursesLocalSupport: {
    id: "renewalPageResoursesLocalSupport",
    defaultMessage:
      "For local support, ask your clinic for a list of support groups that may meet in person or virtually ",
  },
  renewalPageResoursesFindOthers: {
    id: "renewalPageResoursesFindOthers",
    defaultMessage:
      "To find others going through your specific cancer, you can search online for advocacy organizations or call Natera for recommendations",
  },
  renewalPageResoursesNatera: {
    id: "renewalPageResoursesNatera",
    defaultMessage: "For more information about Signatera, visit {nateraLink}.",
  },
  renewalPageResoursesNateraLink: {
    id: "renewalPageResoursesNateraLink",
    defaultMessage: "natera.com/signaterapatients",
  },
  renewalPageFirstVideoAltText: {
    id: "renewalPageFirstVideoAltText",
    defaultMessage: "Keith’s testimonial video",
  },
  renewalPageSecondVideoAltText: {
    id: "renewalPageSecondVideoAltText",
    defaultMessage: "Angela’s testimonial video",
  },
});

interface RenewalInfoDialogProps {
  onCloseDialog: () => void;
  limsId?: number;
  orderUid?: string;
}

const RenewalInfoDialog: FC<RenewalInfoDialogProps> = ({
  onCloseDialog,
  limsId,
  orderUid,
}) => {
  const intl = useIntl();
  const isMobile = useIsMobileDimension();
  const { uppUser, loadProfile } = useContext(UserContext);
  const { config } = React.useContext(ConfigContext);
  const { clear } = useContext(NotificationContext);

  const [isClosing, setIsClosing] = useState<boolean>(false);
  const [firstVideoUrl, setFirstVideoUrl] = useState<string>("");
  const [secondVideoUrl, setSecondVideoUrl] = useState<string>("");

  const TESTIMONIALS_VIDEO = {
    firstStory: "https://natera.wistia.com/medias/suevsq3plh",
    secondStory: "https://natera.wistia.com/medias/n2r97nr1v7",
  };

  React.useEffect(() => {
    const loadVideoUrls = async () => {
      const firstUrl = R.last(TESTIMONIALS_VIDEO.firstStory.split("/")) || "";
      const secondUrl = R.last(TESTIMONIALS_VIDEO.secondStory.split("/")) || "";
      if (firstUrl) {
        setFirstVideoUrl(firstUrl);
      }
      if (secondUrl) {
        setSecondVideoUrl(secondUrl);
      }
    };
    loadVideoUrls();
  }, []);

  React.useEffect(() => {
    if (isMobile) {
      const element = document.querySelector(".renewal-dialog.mobile");
      element && element.classList.add("animate-up");
    }
  }, [isMobile]);

  React.useEffect(() => {
    heap.track(HEAP_EVENTS.upp_viewrenewal_page, {
      identity: uppUser?.patientUID,
      lims_clinic_id: limsId,
      order_uid: orderUid,
    });
  }, []);

  const handleClose = () => {
    setIsClosing(true);
    setTimeout(() => {
      clear();
      onCloseDialog();
    }, 600);
  };

  return (
    <Dialog
      title={intl.formatMessage(messages.renewalPageTitle)}
      showCrossButton
      onClose={handleClose}
      className={`renewal-dialog ${isMobile ? "mobile" : ""} ${
        isClosing ? "animate-down" : ""
      }`}
    >
      <div className="renewal-dialog__content">
        <section>
          <h2 className="renewal-dialog__content__header">
            {intl.formatMessage(messages.renewalPageHeader)}
          </h2>
          <p className="renewal-dialog__content__header-info">
            {intl.formatMessage(messages.renewalPageHeaderInfo)}
          </p>
        </section>
        <section>
          <h3 className="renewal-dialog__content__ensuring">
            {intl.formatMessage(messages.renewalPageEnsuring)}
          </h3>
          <p className="renewal-dialog__content__ensuring-info">
            {intl.formatMessage(messages.renewalPageEnsuringInfo, {
              boldContent: (
                <b>
                  {intl.formatMessage(
                    messages.renewalPageEnsuringInfoBoldContent
                  )}
                </b>
              ),
            })}
          </p>
          <div className="renewal-dialog__content__assistance">
            <b>{intl.formatMessage(messages.renewalPageQuestions)}</b>
            <p>
              {intl.formatMessage(messages.renewalPageAssistance, {
                link: (
                  <a
                    className="renewal-dialog__content__assistance-link"
                    href={`mailto:${config.emails.PATIENT_COORDINATOR}`}
                  >
                    {intl.formatMessage(messages.renewalPageAssistanceLink)}
                  </a>
                ),
              })}
            </p>
          </div>
        </section>
        <section>
          <h3 className="renewal-dialog__content__recurringTesting">
            {intl.formatMessage(messages.renewalPageRecurringTesting)}
          </h3>
          <img
            src={RecurringTestingImage}
            alt={intl.formatMessage(messages.renewalPageRecurringTestingImage)}
            className="renewal-dialog__content__recurringTesting-image"
          />
          <p className="renewal-dialog__content__recurringTesting-info">
            {intl.formatMessage(messages.renewalPageRecurringTestingInfo)}
          </p>
        </section>
        <section className="renewal-dialog__content__testimonials">
          <h3 className="renewal-dialog__content__testimonials-title">
            {intl.formatMessage(messages.renewalPageTestimonials)}
          </h3>
          <div>
            {firstVideoUrl && (
              <div className="renewal-dialog__content__testimonials-video">
                <div className="renewal-dialog__content__testimonials-video__description">
                  <h3 className="renewal-dialog__content__testimonials-video__title">
                    {intl.formatMessage(
                      messages.renewalPageTestimonialsFirstVideoTitle
                    )}
                  </h3>
                  <p className="renewal-dialog__content__testimonials-video__text">
                    {intl.formatMessage(
                      messages.renewalPageTestimonialsFirstVideoText
                    )}
                  </p>
                </div>
                <VideoPlayer
                  id={firstVideoUrl}
                  thumbnailAltText={intl.formatMessage(
                    messages.renewalPageTestimonialsFirstVideoText
                  )}
                />
              </div>
            )}
          </div>
          <div>
            {secondVideoUrl && (
              <div className="renewal-dialog__content__testimonials-video">
                <div className="renewal-dialog__content__testimonials-video__description">
                  <h3 className="renewal-dialog__content__testimonials-video__title">
                    {intl.formatMessage(
                      messages.renewalPageTestimonialsSecondVideoTitle
                    )}
                  </h3>
                  <p className="renewal-dialog__content__testimonials-video__text">
                    {intl.formatMessage(
                      messages.renewalPageTestimonialsSecondVideoText
                    )}
                  </p>
                </div>
                <VideoPlayer
                  id={secondVideoUrl}
                  thumbnailAltText={intl.formatMessage(
                    messages.renewalPageTestimonialsSecondVideoText
                  )}
                />
              </div>
            )}
          </div>
        </section>
        <section className="renewal-dialog__content__resourses">
          <h3 className="renewal-dialog__content__resourses__title">
            {intl.formatMessage(messages.renewalPageResourses)}
          </h3>
          <ul className="renewal-dialog__content__resourses__list">
            <li>
              {intl.formatMessage(messages.renewalPageResoursesCancerSociety, {
                americanCancerSociety: (
                  <a
                    className="renewal-dialog__content__resourses-link"
                    href={config.links.AMERICAN_CANCER_SOCIETY}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {intl.formatMessage(
                      messages.renewalPageAmericanCancerSociety
                    )}
                  </a>
                ),
              })}
            </li>
            <li>
              {intl.formatMessage(messages.renewalPageResoursesLocalSupport)}
            </li>
            <li>
              {intl.formatMessage(messages.renewalPageResoursesFindOthers)}
            </li>
            <li>
              {intl.formatMessage(messages.renewalPageResoursesNatera, {
                nateraLink: (
                  <a
                    className="renewal-dialog__content__resourses-link"
                    href={config.links.SIGNATERA_PATIENTS}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {intl.formatMessage(
                      messages.renewalPageResoursesNateraLink
                    )}
                  </a>
                ),
              })}
            </li>
          </ul>
        </section>
      </div>
    </Dialog>
  );
};

export default RenewalInfoDialog;
