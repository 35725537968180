import React, { FC, useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { defineMessages, useIntl } from "react-intl";
import { Button } from "@natera/material/lib/button";
import { routes } from "@app/routing";
import {
  ConfigContext,
  HeapAnalyticDataContext,
  IntlContext,
  Language,
} from "@app/provider";
import { useTokenizedLinks } from "@app/hooks";
import { WelcomeWrapper } from "./welcomeWrapper";
import { ScheduleBloodDrawScreens } from "../scheduleBloodDraw";

import { LinkHeap } from "@app/components";
import { HEAP_EVENTS, HeapEventLocation } from "@app/provider/types";

import "./welcomeScheduleBloodDraw.scss";
import { isValidLanguageValue, snakeCaseToCapitalizedWords } from "@app/utils";
import { useDialog } from "@natera/platform/lib/hooks";
import AcknowledgementsDialog, {
  ACKNOWLEDGMENT_FLOW,
} from "@app/components/acknowledgmentsDialog/acknowledgmentsDialog";

const messages = defineMessages({
  scheduleBloodDrawDescription: {
    id: "scheduleBloodDrawDescription",
    defaultMessage:
      "Schedule your blood draw by logging into your Natera patient portal account or continuing as a guest.",
  },
  scheduleBloodDrawLogIn: {
    id: "scheduleBloodDrawLogIn",
    defaultMessage: "Log In",
  },
  scheduleBloodDrawSignUp: {
    id: "scheduleBloodDrawSignUp",
    defaultMessage: "Sign Up",
  },
  scheduleBloodDrawContinueAsGuest: {
    id: "scheduleBloodDrawContinueAsGuest",
    defaultMessage: "Continue as a Guest",
  },
  scheduleBloodDrawContactUs: {
    id: "scheduleBloodDrawContactUs",
    defaultMessage: "Contact Us",
  },
  scheduleBloodDrawNeedHelp: {
    id: "scheduleBloodDrawNeedHelp",
    defaultMessage: "Need help? ",
  },
});

interface WelcomeGuestScreenProps {
  token: string;
  setBloodDrawScreen: React.Dispatch<
    React.SetStateAction<ScheduleBloodDrawScreens>
  >;
}

enum GUEST_CHOICE {
  login = "login",
  guest_flow = "guest_flow",
}

const WelcomeGuestScreen: FC<WelcomeGuestScreenProps> = ({
  token,
  setBloodDrawScreen,
}) => {
  const history = useHistory();
  const intl = useIntl();

  const { config } = React.useContext(ConfigContext);
  const { changeLanguage, currentLanguage } = useContext(IntlContext);

  const { drawRequestDataForHeapEventData } = React.useContext(
    HeapAnalyticDataContext
  );
  const { checkUserExistByToken, getTokenSource } = useTokenizedLinks();

  const [isUserExist, setUserExist] = React.useState<boolean>(false);

  const checkUserExist = async (token: string) => {
    const userExist = await checkUserExistByToken(token);
    setUserExist(Boolean(userExist));
  };

  const setUserLanguage = async (token: string) => {
    const source = await getTokenSource(token);
    const language = source?.language;
    if (
      language &&
      currentLanguage !== language &&
      isValidLanguageValue(language)
    ) {
      changeLanguage(language as Language);
    }
  };

  const handleChangePath = () => {
    heap.track(HEAP_EVENTS.upp_mp_guestflow, {
      business_unit: drawRequestDataForHeapEventData?.business_unit,
      guest_choice: GUEST_CHOICE.login,
      test_name: snakeCaseToCapitalizedWords(
        drawRequestDataForHeapEventData?.test_name
      ),
      lims_clinic_id: drawRequestDataForHeapEventData?.lims_clinic_id,
    });
    const url = isUserExist ? routes.signIn : routes.signUp;
    history.push(url + history.location.search);
  };

  const acknowdledgementsDialog = useDialog(AcknowledgementsDialog);

  const handleClickAsGuest = () => {
    acknowdledgementsDialog.open({
      userEmail: "dummyEmail@guest.com",
      onAgreeAcknowledgements: () => {
        heap.track(HEAP_EVENTS.upp_mp_guestflow, {
          business_unit: drawRequestDataForHeapEventData?.business_unit,
          guest_choice: GUEST_CHOICE.guest_flow,
          test_name: drawRequestDataForHeapEventData?.test_name,
          lims_clinic_id: drawRequestDataForHeapEventData?.lims_clinic_id,
        });
        setBloodDrawScreen(ScheduleBloodDrawScreens.WELCOME_DOB_VERIFY_SCREEN);
      },
      onCloseDialog: acknowdledgementsDialog.close,
      flow: ACKNOWLEDGMENT_FLOW.GUEST,
    });
  };

  useEffect(() => {
    if (token) {
      checkUserExist(token);
      setUserLanguage(token);
    }
  }, [token]);

  return (
    <WelcomeWrapper>
      {acknowdledgementsDialog.getDialog()}
      <div className="schedule-blood-draw__content-description">
        {intl.formatMessage(messages.scheduleBloodDrawDescription)}
      </div>
      <div className="schedule-blood-draw__buttons-container">
        <Button type="button" raised onClick={handleChangePath}>
          {isUserExist
            ? intl.formatMessage(messages.scheduleBloodDrawLogIn)
            : intl.formatMessage(messages.scheduleBloodDrawSignUp)}
        </Button>
        <Button type="button" outlined onClick={handleClickAsGuest}>
          {intl.formatMessage(messages.scheduleBloodDrawContinueAsGuest)}
        </Button>
        <p>
          {intl.formatMessage(messages.scheduleBloodDrawNeedHelp)}&nbsp;
          <LinkHeap
            target="_blank"
            key="privacyPolicy"
            rel="noopener noreferrer"
            to={{ pathname: config.links.CONTACT_US }}
            heapEventName={HEAP_EVENTS.upp_click_contactnatera}
            heapEventProps={{
              location: HeapEventLocation.mp_flow_initiation_page,
            }}
          >
            {intl.formatMessage(messages.scheduleBloodDrawContactUs)}
          </LinkHeap>
        </p>
      </div>
    </WelcomeWrapper>
  );
};

export default WelcomeGuestScreen;
