import { DateTime } from "luxon";
import { defineMessages, IntlShape } from "react-intl";

const messages = defineMessages({
  timeZoneUserValueHawaii: {
    id: "timeZoneUserValueHawaii",
    defaultMessage: "Hawaii",
  },
  timeZoneUserValueAlaska: {
    id: "timeZoneUserValueAlaska",
    defaultMessage: "Alaska",
  },
  timeZoneUserValuePacificTime: {
    id: "timeZoneUserValuePacificTime",
    defaultMessage: "Pacific Time",
  },
  timeZoneUserValueArizona: {
    id: "timeZoneUserValueArizona",
    defaultMessage: "Arizona",
  },
  timeZoneUserValueMountainTime: {
    id: "timeZoneUserValueMountainTime",
    defaultMessage: "Mountain Time",
  },
  timeZoneUserValueCentralTime: {
    id: "timeZoneUserValueCentralTime",
    defaultMessage: "Central Time",
  },
  timeZoneUserValueIndiana: {
    id: "timeZoneUserValueIndiana",
    defaultMessage: "Indiana (Eastern Time)",
  },
  timeZoneUserValueEasternTime: {
    id: "timeZoneUserValueEasternTime",
    defaultMessage: "Eastern Time",
  },
});

interface TimeZone {
  userValue: string;
  name: string;
  browserValue: string;
}

export const getTranslatedTimeZoneUserValue = (
  browserValue: string,
  intl: IntlShape
): string => {
  switch (browserValue) {
    case "Pacific/Honolulu":
      return intl.formatMessage(messages.timeZoneUserValueHawaii);
    case "America/Anchorage":
      return intl.formatMessage(messages.timeZoneUserValueAlaska);
    case "America/Los_Angeles":
      return intl.formatMessage(messages.timeZoneUserValuePacificTime);
    case "America/Phoenix":
      return intl.formatMessage(messages.timeZoneUserValueArizona);
    case "America/Denver":
      return intl.formatMessage(messages.timeZoneUserValueMountainTime);
    case "America/Chicago":
      return intl.formatMessage(messages.timeZoneUserValueCentralTime);
    case "America/Indianapolis":
      return intl.formatMessage(messages.timeZoneUserValueIndiana);
    case "America/New_York":
      return intl.formatMessage(messages.timeZoneUserValueEasternTime);
    default:
      return "";
  }
};

export const getTimeZones = (intl: IntlShape): TimeZone[] => {
  return TIMEZONES.map((tz) => {
    return {
      userValue: getTranslatedTimeZoneUserValue(tz.browserValue, intl),
      name: tz.name,
      browserValue: tz.browserValue,
    };
  });
};

export enum TimeZoneType {
  HAWAII = "HAWAII",
  ALASKA = "ALASKA",
  PACIFIC_TIME = "PACIFIC_TIME",
  ARIZONA = "ARIZONA",
  MOUNTAIN_TIME = "MOUNTAIN_TIME",
  CENTRAL_TIME = "CENTRAL_TIME",
  INDIANA = "INDIANA",
  EASTERN_TIME = "EASTERN_TIME",
}

export const getLocalForTimezone = {
  [TimeZoneType.HAWAII]: "Pacific/Honolulu",
  [TimeZoneType.ALASKA]: "America/Anchorage",
  [TimeZoneType.PACIFIC_TIME]: "America/Los_Angeles",
  [TimeZoneType.ARIZONA]: "America/Phoenix",
  [TimeZoneType.MOUNTAIN_TIME]: "America/Denver",
  [TimeZoneType.CENTRAL_TIME]: "America/Chicago",
  [TimeZoneType.INDIANA]: "America/Indianapolis",
  [TimeZoneType.EASTERN_TIME]: "America/New_York",
};

const TIMEZONES = [
  { name: TimeZoneType.HAWAII, browserValue: "Pacific/Honolulu" },
  { name: TimeZoneType.ALASKA, browserValue: "America/Anchorage" },
  {
    name: TimeZoneType.PACIFIC_TIME,
    browserValue: "America/Los_Angeles",
  },
  { name: TimeZoneType.ARIZONA, browserValue: "America/Phoenix" },
  {
    name: TimeZoneType.MOUNTAIN_TIME,
    browserValue: "America/Denver",
  },
  {
    name: TimeZoneType.CENTRAL_TIME,
    browserValue: "America/Chicago",
  },
  {
    name: TimeZoneType.INDIANA,
    browserValue: "America/Indianapolis",
  },
  {
    name: TimeZoneType.EASTERN_TIME,
    browserValue: "America/New_York",
  },
];

const TIMEZONE_SHORT = [
  { value: "Pacific/Honolulu", short: "HST" },
  { value: "America/Anchorage", short: "AKD" },
  { value: "America/Los_Angeles", short: "PDT" },
  { value: "America/Phoenix", short: "MST" },
  { value: "America/Denver", short: "MDT" },
  { value: "America/Chicago", short: "CDT" },
  { value: "America/Indianapolis", short: "EDT" },
  { value: "America/New_York", short: "EDT" },
];

export const getNameTimezones = (value: string): string => {
  const timezone = TIMEZONES.find((tz) => tz.browserValue === value);
  return timezone?.name || "";
};

export const getTimeZoneShort = (value: string): string | undefined =>
  TIMEZONE_SHORT.find((timeZone) => timeZone.value === value)?.short;

export const getUserTimezonesValues = (intl: IntlShape): string[] =>
  getTimeZones(intl).map((timeZone) => timeZone.userValue);

export const getBrowserTimezonesValues = TIMEZONES.map(
  (timeZone) => timeZone.browserValue
);

export const getUserTimezoneValue = (
  value: string | undefined,
  intl: IntlShape
): string | undefined =>
  getTimeZones(intl).find((timeZone) => timeZone.browserValue === value)
    ?.userValue;

export const getBrowserTimezoneValue = (
  value: string | undefined,
  intl: IntlShape
): string | undefined =>
  getTimeZones(intl).find((timeZone) => timeZone.userValue === value)
    ?.browserValue;

export const getTimezoneDeltaInHours = (): number =>
  new Date().getTimezoneOffset() / 60;

export const getDateTimeWithTimeZoneDelta = (date: Date): Date =>
  new Date(date.setHours(date.getHours() + getTimezoneDeltaInHours()));

export const formatToUTCO = (slot: Date): string =>
  `${slot.getFullYear()}-${
    slot.getMonth() + 1 < 10 ? "0" + slot.getMonth() + 1 : slot.getMonth() + 1
  }-${slot.getDate() < 10 ? "0" + slot.getDate() : slot.getDate()}T${
    slot.getHours() < 10 ? "0" + slot.getHours() : slot.getHours()
  }:00:00+00:00`;

type GetTimeZoneFormat = (
  date: Date,
  selectedTimeZone: string | undefined,
  intl: IntlShape
) => string;

export const getTimeZoneFormat: GetTimeZoneFormat = (
  date,
  selectedTimeZone,
  intl
) => {
  return date
    .toLocaleTimeString("en-us", {
      timeZoneName: "short",
      timeZone: getBrowserTimezoneValue(selectedTimeZone, intl),
    })
    .split(" ")[2]
    .slice(0, 3);
};

const getTimezoneShort = (startDate: string, timeZone: string) => {
  return DateTime.fromISO(startDate).setZone(timeZone).toFormat("ZZZZ");
};

export const getTimeSlot = (
  startDate: string,
  endDate: string,
  timeZone?: string
): string => {
  const browserTimeZone = DateTime.local().offsetNameShort;
  const browserTimeZoneName = DateTime.local().zoneName;

  const slotTimeZone = timeZone ? "utc" : browserTimeZoneName;

  const formattedDate = DateTime.fromISO(startDate, { zone: "utc" })
    .setZone(slotTimeZone)
    .toFormat("M/d/yy");

  const startTime = DateTime.fromISO(startDate, { zone: "utc" })
    .setZone(slotTimeZone)
    .toFormat("hh:mm a", {
      locale: "en-US",
    });

  const endTime = DateTime.fromISO(endDate, { zone: "utc" })
    .setZone(slotTimeZone)
    .toFormat("hh:mm a", {
      locale: "en-US",
    });

  return `${formattedDate} ${startTime} - ${endTime}, ${
    timeZone ? getTimezoneShort(startDate, timeZone) : browserTimeZone || ""
  }`;
};

export const getFormattedMaintenanceDateTime = (dateTime: string): string => {
  const browserTimeZoneName = DateTime.local().zoneName;

  const formattedDate = DateTime.fromISO(dateTime, { zone: "utc" })
    .setZone(browserTimeZoneName)
    .toFormat("M/d/yy");

  const formattedTime = DateTime.fromISO(dateTime, { zone: "utc" })
    .setZone(browserTimeZoneName)
    .toFormat("hh:mm a", {
      locale: "en-US",
    });

  return `${formattedDate} ${formattedTime}`;
};
