import React, { FC, useContext, useEffect, useRef } from "react";
import { sanitize } from "dompurify";
import Button from "@natera/material/lib/button";
import { Dialog } from "@natera/material/lib/dialog";
import "./closeWidgetDialog.scss";
import {
  generateActionUserEvent,
  unescape,
  wrapTextAddLinks,
} from "@app/neva/helpers";
import { ActionTypes } from "@app/neva/models";
import { APIContext, EventsContext } from "@app/neva/provider";
import { IntlContext } from "@app/provider";
import { defineMessages, useIntl } from "react-intl";
import { useLinkHandler } from "@app/neva/hooks/useLinkHandler";
import { SANITIZE_OPTIONS } from "@app/neva/models/constants";

interface Props {
  onClose: () => void;
  shouldShowClosePopupScheduleGCText?: boolean;
}

const messages = defineMessages({
  chatMessageDialogExternalLink: {
    id: "chatMessageDialogExternalLink",
    defaultMessage: "Open the external resource link",
  },
});

const CloseWidgetDialog: FC<Props> = ({
  onClose,
  shouldShowClosePopupScheduleGCText,
}) => {
  const output = useRef<HTMLDivElement>(null);
  const intl = useIntl();
  const { linkEditorListener } = useLinkHandler();
  const { userEventHandler } = useContext(EventsContext);
  const {
    apiProviderState: { widgetSettings },
  } = useContext(APIContext);
  const { currentLanguage } = useContext(IntlContext);
  const closeWidget =
    widgetSettings?.languageConfigs[currentLanguage].widgetPopup.closeWidget;

  useEffect(() => {
    linkEditorListener(output, onClose);
  }, []);

  const handleCloseWidgetDialogClose = () => {
    userEventHandler(generateActionUserEvent(ActionTypes.CLOSE_WIDGET));
    onClose();
  };

  const handleCloseWidgetDialogContinue = () => {
    userEventHandler(generateActionUserEvent(ActionTypes.KEEP_CHATTING));
    onClose();
  };

  const actions = (
    <>
      <Button onClick={handleCloseWidgetDialogClose} outlined>
        {closeWidget?.closeChatButtonText}
      </Button>
      <Button onClick={handleCloseWidgetDialogContinue} raised>
        {closeWidget?.keepChattingButtonText}
      </Button>
    </>
  );

  return (
    <Dialog
      className="close-widget-dialog"
      onClose={onClose}
      actions={actions}
      title={closeWidget?.popupTitle}
      focusTrapOptions={{ skipInitialFocus: true }}
    >
      <div ref={output} className="text-message__wrapper">
        <div className="dialog-container">{closeWidget?.popupText}</div>
        {shouldShowClosePopupScheduleGCText && (
          <div
            className="dialog-container schedule-gc"
            dangerouslySetInnerHTML={{
              __html: sanitize(
                wrapTextAddLinks(
                  unescape(closeWidget?.popupScheduleGCText as string),
                  intl.formatMessage(messages.chatMessageDialogExternalLink)
                ),
                SANITIZE_OPTIONS
              ),
            }}
          />
        )}
      </div>
    </Dialog>
  );
};

export default CloseWidgetDialog;
