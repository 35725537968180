import * as React from "react";
import * as R from "ramda";
import { useStorage } from "@natera/platform/lib/hooks";
import { Notification } from "@app/components";
import { NotificationType } from "@app/components/notification/notification";
import { defineMessages, useIntl } from "react-intl";
import { datadogRum } from "@datadog/browser-rum";

type NotificationId = string;

export type NotificationMessageType = "text";

export type Clear = () => void;

export interface AddNotificationParameters {
  id?: string;
  title?: React.ReactNode;
  message?: React.ReactNode;
  type?: NotificationType;
  messageType?: NotificationMessageType;
  actions?: React.ReactNode;
  hasTimeout?: boolean;
  ms?: number;
}

export type AddNotification = (
  params: AddNotificationParameters
) => NotificationId;

export type RemoveNotification = (id: NotificationId) => void;

export interface NotificationController {
  clear: Clear;
  addNotification: AddNotification;
  removeNotification: RemoveNotification;
  notifications: React.ReactNode;
}

interface NotificationProvider {
  timeout?: number;
}

interface StorageNotification extends AddNotificationParameters {
  id: NotificationId;
}

export const Context = React.createContext<NotificationController>({
  clear: () => undefined,
  addNotification: () => "0",
  removeNotification: () => undefined,
  notifications: undefined,
});

const messages = defineMessages({
  commonErrorText: {
    id: "commonErrorText",
    defaultMessage: "We're sorry. Something went wrong.",
  },
});

const COMMON_ERROR_ID = "COMMON_ERROR_ID";

Context.displayName = "NotificationContext";

const NotificationProvider: React.FunctionComponent<NotificationProvider> = ({
  children,
}) => {
  const intl = useIntl();

  const notificationStorage = useStorage<StorageNotification>({
    initialRecords: [],
  });
  const idSeq = React.useRef(0);

  const clear: Clear = () => {
    notificationStorage.clear();
  };

  const addNotification: AddNotification = (
    params: AddNotificationParameters
  ) => {
    let id: string;
    const isCommonError = !params.message && !params.title;
    if (params.id) {
      id = params.id;
    } else if (isCommonError) {
      id = COMMON_ERROR_ID;
      // send datadog RUM error
      datadogRum.addError(new Error("Default error notification displayed."), {
        params: params,
      });
    } else {
      idSeq.current += 1;
      id = `${idSeq.current}`;
    }

    const notification = notificationStorage.getRecord(id);
    if (notification) {
      return id;
    }

    notificationStorage.addRecord({
      id,
      title: params.title,
      message: params.message,
      type: params?.type || "info",
      messageType: params.messageType,
      actions: params?.actions,
    });

    window.scrollTo(0, 0);

    if (params.hasTimeout) {
      const timeout = params.ms ? params.ms : 5000;
      setTimeout(() => {
        notificationStorage.clear();
      }, timeout);
    }

    return id;
  };

  const removeNotification: RemoveNotification = (id) => {
    notificationStorage.removeRecord(id.toString());
  };

  const notificationArray = R.map(([id, notification]) => {
    const getNotificationTitle = () => {
      if (notification.title) {
        return notification.messageType === "text"
          ? intl.formatMessage(notification.title)
          : notification.title;
      }
    };

    const getNotificationMessage = () => {
      if (!notification.message && !notification.title) {
        return intl.formatMessage(messages.commonErrorText);
      } else if (notification.message) {
        return notification.messageType === "text"
          ? intl.formatMessage(notification.message)
          : notification.message;
      }
    };

    const title = getNotificationTitle();
    const message = getNotificationMessage();

    return (
      <Notification
        key={id}
        type={notification.type}
        titleElem={title}
        actions={notification.actions}
      >
        {message}
      </Notification>
    );
  })(
    R.zip(
      R.keys(notificationStorage.getRecords()),
      R.values(notificationStorage.getRecords())
    )
  );

  const notifications = (
    <div className="mobile-view__container" role="alert">
      {notificationArray}
    </div>
  );

  return (
    <Context.Provider
      value={{
        clear,
        addNotification,
        removeNotification,
        notifications,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export default NotificationProvider;
