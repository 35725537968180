import { SampleDrawKitStep } from "@app/components/sampleDraw/steps";
import React, { FC } from "react";
import { ScheduleBloodDrawScreens } from "../scheduleBloodDraw";
import { DrawRequestContext, KitContext } from "@app/provider";
import { LoadingContext } from "@natera/platform/lib/components/context";

import "./kitDetails.scss";

type KitDetailsProps = {
  setBloodDrawScreen: React.Dispatch<
    React.SetStateAction<ScheduleBloodDrawScreens>
  >;
  token: string;
};

const KitDetails: FC<KitDetailsProps> = ({ setBloodDrawScreen, token }) => {
  const {
    verifiedSampleDrawData,
    verifySampleDrawWithoutLogging,
    checkSampleDraw,
    isLoading,
  } = React.useContext(DrawRequestContext);
  const { setSkipKitShipping, setSkipLabStep } = React.useContext(KitContext);

  const orderUid = verifiedSampleDrawData?.sampleInfo.sample?.orderUid;
  const testUid = verifiedSampleDrawData?.sampleInfo.sample?.testUid;
  const profileAddresses =
    verifiedSampleDrawData?.profileInfo.profile?.addresses;
  const dateOfBirth = verifiedSampleDrawData?.profileInfo.profile?.dateOfBirth;

  const handleKitShippingStepPassed = async () => {
    if (dateOfBirth) {
      await verifySampleDrawWithoutLogging(token, dateOfBirth);
    }
    setBloodDrawScreen(
      ScheduleBloodDrawScreens.MOBILE_PHLEBOTOMY_STEPPER_SCREEN
    );
  };

  const onBack = async () => {
    setBloodDrawScreen(ScheduleBloodDrawScreens.PERSONAL_INFO_SCREEN);
  };

  const handleCheckSample = async (
    orderUid: string,
    testUid: string,
    token: string
  ) => {
    const response = await checkSampleDraw(orderUid, testUid, token);
    if (!response?.needsKitShippingStep) {
      handleKitShippingStepPassed();
    }

    setSkipLabStep(Boolean(response?.skipLabStep));
    setSkipKitShipping(!response?.needsKitShippingStep);
  };

  React.useEffect(() => {
    if (orderUid && testUid) {
      handleCheckSample(orderUid, testUid, token);
    }
  }, []);

  return (
    <LoadingContext isLoading={isLoading}>
      <div className="guest-kit-details">
        <div className="guest-kit-details__content">
          <SampleDrawKitStep
            onSubmit={handleKitShippingStepPassed}
            onBack={onBack}
            orderUid={orderUid}
            testUid={testUid}
            profileAddresses={profileAddresses}
            token={token}
          />
        </div>
      </div>
    </LoadingContext>
  );
};

export default KitDetails;
