import React, { FC, useEffect } from "react";
import MobilePhlebotomyStepper from "@app/pages/private/sampleDraw/create/mobilePhlebotomyStepper";
import {
  DrawRequestContext,
  KitContext,
  OrderRenewalContext,
} from "@app/provider";
import { ScheduleBloodDrawScreens } from "../scheduleBloodDraw";
import "./scheduleBloodDrawStepper.scss";
import { TestType } from "@app/provider/testData/types";

interface ScheduleBloodDrawStepperProps {
  token: string;
  setBloodDrawScreen: React.Dispatch<
    React.SetStateAction<ScheduleBloodDrawScreens>
  >;
}

const ScheduleBloodDrawStepper: FC<ScheduleBloodDrawStepperProps> = ({
  token,
  setBloodDrawScreen,
}) => {
  const { verifiedSampleDrawData } = React.useContext(DrawRequestContext);
  const { getRenewalInfo } = React.useContext(OrderRenewalContext);
  const { skipKitShipping } = React.useContext(KitContext);

  const handleOnBackPreferenceStep = () =>
    setBloodDrawScreen(ScheduleBloodDrawScreens.PERSONAL_INFO_SCREEN);

  const handleOnBackKitShippingStep = () =>
    setBloodDrawScreen(
      skipKitShipping
        ? ScheduleBloodDrawScreens.PERSONAL_INFO_SCREEN
        : ScheduleBloodDrawScreens.KIT_DETAILS_SCREEN
    );
  const handleCreateDrawRequestError = () =>
    setBloodDrawScreen(
      ScheduleBloodDrawScreens.CREATE_BLOOD_DRAW_FAILED_SCREEN
    );

  const testType = verifiedSampleDrawData?.sampleInfo.sample?.testType;
  const orderUid = verifiedSampleDrawData?.sampleInfo.sample?.orderUid;

  useEffect(() => {
    if (testType === TestType.SIGNATERA && orderUid) {
      getRenewalInfo({ orderUid, token });
    }
  }, [token]);

  return (
    <div className="guest-schedule-blood-draw">
      <div className="guest-schedule-blood-draw__content">
        <MobilePhlebotomyStepper
          onBackPreferenceStep={handleOnBackPreferenceStep}
          onBackKitShippingStep={handleOnBackKitShippingStep}
          handleCreateDrawRequestError={handleCreateDrawRequestError}
          testType={testType}
          orderUid={orderUid}
          testUid={verifiedSampleDrawData?.sampleInfo.sample?.testUid}
          profileAddresses={
            verifiedSampleDrawData?.profileInfo.profile?.addresses
          }
          token={token}
        />
      </div>
    </div>
  );
};

export default ScheduleBloodDrawStepper;
