import React from "react";

import { Dialog } from "@natera/material/lib/dialog";
import { Button } from "@natera/platform/lib/components/form";
import { defineMessages, useIntl } from "react-intl";
import { ConfigContext } from "@app/provider";
import { HEAP_EVENTS } from "@app/provider/types";
import { LinkHeap } from "@app/components";

const messages = defineMessages({
  kitErrorDialogHeader: {
    id: "kitErrorDialogHeader",
    defaultMessage: "Kit cannot be ordered at this time",
  },
  kitErrorDialogDescription: {
    id: "kitErrorDialogDescription",
    defaultMessage:
      "We’re unable to complete your kit order. Please {contactUs} to request a kit.",
  },
  kitErrorDialogDescriptionContactUs: {
    id: "kitErrorDialogDescriptionContactUs",
    defaultMessage: "contact us",
  },
  kitErrorDialogButtonError: {
    id: "kitErrorDialogButtonError",
    defaultMessage: "Done",
  },
});

type Props = {
  onClose: () => void;
};

const KitErrorDialog: React.FC<Props> = ({ onClose }) => {
  const intl = useIntl();
  const { config } = React.useContext(ConfigContext);

  return (
    <Dialog
      className="mobile-phlebotomy-modal"
      onClose={onClose}
      showCrossButton
      title={intl.formatMessage(messages.kitErrorDialogHeader)}
      actions={
        <Button raised onClick={onClose}>
          {intl.formatMessage(messages.kitErrorDialogButtonError)}
        </Button>
      }
    >
      <div className="mobile-phlebotomy-modal__container">
        <p>
          {intl.formatMessage(messages.kitErrorDialogDescription, {
            contactUs: (
              <LinkHeap
                target="_blank"
                key="privacyPolicy"
                rel="noopener noreferrer"
                to={{ pathname: config.links.CONTACT_US }}
                heapEventName={HEAP_EVENTS.upp_click_contactnatera}
              >
                {intl.formatMessage(
                  messages.kitErrorDialogDescriptionContactUs
                )}
              </LinkHeap>
            ),
          })}
        </p>
      </div>
    </Dialog>
  );
};

export default KitErrorDialog;
